/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { msalInstance } from 'msalconfig';
import { Button } from 'primereact/button';
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver';
// eslint-disable-next-line import/no-extraneous-dependencies
import Papa from 'papaparse';
import { DataTableSortOrderType } from 'primereact/datatable';

import Header from '@components/Header/Header';
import SideBar from '@components/SideBar/SideBar';
import { Table } from '@components/Table/Table';
import { ExportFileModal } from '@components/ExportFileModal/ExportFileModal';
import { ColumnHeaders, MessageSeverityEnum, ListPages } from '@enums/enums';
import { fetchDashboardReportData } from '@services/funds-transaction-reports';

import '@styles/main.scss';
import { DashboardReportType } from '../../types/responses/dashboard-report';
import styles from './DashboardReports.module.scss';

import { validationToken } from './Utils';

const DashboardReports = (props: any) => {
  const defaultPageSize = 15;
  const defaultCurrentPage = 1;
  const firstPageCursor = {};

  const transactionId: string | null = null;
  // const transactionType: string | null = null;
  const operationStatus: string | null = null;
  const cardNumber: string | null = null;
  const amount: number | null = null;
  const startDate: string | null = null;
  const endDate: string | null = null;

  // Cursor and additional options
  const nextCursor: string | null = null;
  const column: string | null = null;
  const order: string | null = null;
  const dataType = 'json';

  const navigate = useNavigate();
  const city = validationToken();
  const merchantId = city || '';

  useEffect(() => {
    if (!city) {
      sessionStorage.clear();
      try {
        msalInstance.logoutRedirect({
          postLogoutRedirectUri: window.location.origin + ListPages.Login,
        });
      } catch (logoutError) {
        console.error('Error during logout:', logoutError);
        navigate(ListPages.Login);
      }
    }
  }, [navigate, city]);

  const defaultCursor = {
    filters: {
      ...{ merchantId },
      ...((transactionId && { transactionId }) as unknown as object),
      // ...((transactionType && { transactionType }) as unknown as object),
      ...((operationStatus && { operationStatus }) as unknown as object),
      ...((cardNumber && { cardNumber }) as unknown as object),
      ...((amount && { amount }) as unknown as object),
      ...(((startDate || endDate) && {
        dateRange: {
          ...((startDate && { startDate }) as unknown as object),
          ...((endDate && { endDate }) as unknown as object),
        },
      }) as unknown as object),
    },

    ...{ dataType },
  };

  // End default cursor

  const { t } = useTranslation();

  // Table States
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState<string[]>([]);
  const [transactionData, setTransactionData] = useState<any>({});

  // Filters States
  const [isCooldownTime, setIsCooldownTime] = useState<boolean>(false);
  const [requestCursor, setRequestCursor] =
    useState<PaginationCursorType>(defaultCursor);
  const [searchValues, setSearchValues] = useState<HandlerType>({
    isFilterChange: false,
    isPageSizeChanged: false,
    search: '',
    selectedSearchFilterType: undefined,
    selectedTransactionType: undefined,
    selectedOperationStatus: undefined,
    currentPage: defaultCurrentPage,
    pageSize: defaultPageSize,
  });

  const [orderValues, setOrderValues] = useState<SortByType>({
    column: '',
    order: '',
  });
  const [sortOrder, setSortOrder] = useState<DataTableSortOrderType>(0);

  // Logic states
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  // Pagination States
  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [isPrevPage, setIsPrevPage] = useState(false);
  const [isNextPage, setIsNextPage] = useState(false);
  const [pagesAmount, setPagesAmount] = useState();
  const [cursorKeyCollection, setCursorKeyCollection] = useState<
    LastEvaluatedKeyType[]
  >([{}]);
  const [resultAmount, setResultAmount] = useState(0);

  // Errors and Messages States
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('Refresh the list to see new updates');
  const [messageSeverity, setMessageSeverity] = useState(
    'info' as MessageSeverityEnum
  );

  const rowsPerPageOptions = [15, 20, 30, 50];

  const columnHeaders = [
    [
      ColumnHeaders.transactionId,
      t('transactionsHistory.columnHeaders.transactionId'),
    ],
    [
      ColumnHeaders.cardNumber,
      t('transactionsHistory.columnHeaders.cardNumber'),
    ],
    [
      ColumnHeaders.merchantDate,
      t('transactionsHistory.columnHeaders.merchantDate'),
    ],
    [ColumnHeaders.authDate, t('transactionsHistory.columnHeaders.authDate')],
    [
      ColumnHeaders.transactionType,
      t('transactionsHistory.columnHeaders.transactionType'),
    ],
    [
      ColumnHeaders.operationStatus,
      t('transactionsHistory.columnHeaders.operationStatus'),
    ],
    [ColumnHeaders.amount, t('transactionsHistory.columnHeaders.amount')],
  ];

  function formatDate(inputDateString: string) {
    const inputDate = new Date(inputDateString);
    const day = inputDate.getUTCDate().toString().padStart(2, '0');
    const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const year = inputDate.getUTCFullYear().toString();
    const hours = inputDate.getUTCHours().toString().padStart(2, '0');
    const minutes = inputDate.getUTCMinutes().toString().padStart(2, '0');
    const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes} GT`;
    return formattedDate;
  }

  const tableTransactionData = (fundsData: any) => {
    const data = fundsData?.map((e: any) => {
      const amountFormatted = e?.amount?.toLocaleString('en-US') || null;
      return {
        transactionId: e.transactionId,
        cardNumber: e.cardNumber,
        merchantDate: e.merchantDate ? formatDate(e.merchantDate) : null,
        authDate: formatDate(e.authDate),
        // transactionType: e.transactionType,
        operationStatus: e.operationStatus,
        amount: amountFormatted ? `$ ${amountFormatted}` : null,
      };
    });
    return data;
  };

  const filterSelectedOption = null;
  const searchedText = null;

  const resetFilters = () => {
    // filterSelectedOption = null;
    // searchedText = null;
    // transactionId = null;
    // transactionType = null;
    // cardNumber = null;
    // amount = null;
    // startDate = null;
    // endDate = null;
  };

  const createCursor = (
    isFilterChange: boolean,
    isPageSizeChanged: boolean,
    pageSize: number,
    search?: string,
    selectedSearchFilterType?: any,
    selectedTransactionType?: any,
    selectedOperationStatus?: any,
    pageNumber?: number,
    orderColumn?: string,
    orderDirection?: string
  ): PaginationCursorType => {
    // Data from Filter
    const transactionId: string | null =
      selectedSearchFilterType?.code === 'transactionId' && search
        ? search
        : null;
    // const transactionType: string | null = selectedTransactionType
    //   ? selectedTransactionType.code
    //   : null;
    const operationStatus: string | null = selectedOperationStatus
      ? selectedOperationStatus.code
      : null;
    const cardNumber: string | null =
      selectedSearchFilterType?.code === 'cardNumber' && search ? search : null;
    const amount: number | null =
      selectedSearchFilterType?.code === 'amount' && search
        ? (+search as unknown as number)
        : null;

    // Cursor and additional options
    const startDate: string | null = null;
    const endDate: string | null = null;
    const nextCursor: string | null = null;
    const column: string | null = null;
    const order: string | null = null;
    const dataType = 'json';

    if (isPageSizeChanged && pageSize) {
      setCurrentPageSize(pageSize);
    }

    const cursor: PaginationCursorType = {
      ...((!isFilterChange &&
        !isPageSizeChanged &&
        pageNumber !== 1 && {
          lastEvaluatedKey: cursorKeyCollection[(pageNumber as number) - 1],
        }) as unknown as object),
      filters: {
        ...{ merchantId },
        ...((transactionId && { transactionId }) as unknown as object),
        // ...((transactionType && { transactionType }) as unknown as object),
        ...((operationStatus && { operationStatus }) as unknown as object),
        ...((cardNumber && { cardNumber }) as unknown as object),
        ...((amount && { amount }) as unknown as object),
        ...(((startDate || endDate) && {
          dateRange: {
            ...((startDate && { startDate }) as unknown as object),
            ...((endDate && { endDate }) as unknown as object),
          },
        }) as unknown as object),
      },
      ...((orderColumn &&
        orderDirection && {
          sortBy: {
            ...((orderColumn && { column: orderColumn }) as unknown as object),
            ...((orderDirection && {
              order: orderDirection,
            }) as unknown as object),
          },
        }) as unknown as object),
      // ...((column || order) && {
      //   sortBy: {
      //     column,
      //     order,
      //   },
      // }),
      ...{ dataType },
    };
    // return cursor;
    setRequestCursor(cursor);
    if (isFilterChange) {
      setCursorKeyCollection([firstPageCursor]);
      setCurrentPage(defaultCurrentPage);
    }
    return cursor;
  };

  const setCoolDown = async () => {
    setIsCooldownTime(true);
    setTimeout(() => {
      setIsCooldownTime(false);
    }, 30000);
  };

  const addNextCursor = (
    cursorId: LastEvaluatedKeyType,
    filterChange?: boolean
  ) => {
    setCursorKeyCollection(
      filterChange
        ? [firstPageCursor, cursorId]
        : [...cursorKeyCollection, cursorId]
    );
  };

  const fetchData = async (
    cursor?: PaginationCursorType,
    newPageSize?: number,
    filterChange?: boolean
  ): Promise<DashboardReportType | undefined> => {
    setIsLoading(true);
    setCoolDown();
    const finalCursor: PaginationCursorType = cursor || requestCursor;
    let res: DashboardReportType | undefined;
    try {
      const pagination = newPageSize || currentPageSize;
      res = (await fetchDashboardReportData(
        finalCursor,
        pagination
      )) as DashboardReportType;
    } catch (err) {
      setTransactionData({});
    }

    const transactions = tableTransactionData(res?.data);

    setIsNextPage(!!res?.lastEvaluatedKey);

    const toStore = {
      data: transactions,
      total: res?.totalOnPage,
      lastEvaluatedKey: res?.lastEvaluatedKey,
    };
    setTransactionData(toStore);
    if (res?.lastEvaluatedKey) {
      const retrivedDataCursor = res?.lastEvaluatedKey;
      addNextCursor(retrivedDataCursor, filterChange);
    }
    setResultAmount(res?.totalResults || 1);
    setIsLoading(false);
    return res;
  };

  const downloadFile = async (dateRange: DateRangeType) => {
    const downloadCursor: DownloadCursorType = {
      filters: { ...requestCursor.filters, dateRange },
      ...{ dataType: 'csv' },
    };

    try {
      const res = (await fetchDashboardReportData(
        downloadCursor
      )) as DownloadResponse;
      const data = res?.data;
      const fileName = data?.fileName;
      // Decode base64 and parse as CSV
      const csvData = atob(data.base64String);
      const parsedData = Papa.parse(csvData, { header: true });

      // Convert parsed data back to CSV
      const csvBlob = new Blob(
        [Papa.unparse(parsedData as unknown as unknown[])],
        { type: 'text/csv' }
      );

      // Create a file with a unique name and save it
      saveAs(csvBlob, fileName);
    } catch (err) {
      setTransactionData({});
    }
  };

  const searchButtonHandler = async (
    isFilterChange: boolean,
    isPageSizeChanged: boolean,
    search?: string,
    selectedSearchFilterType?: any,
    selectedTransactionType?: any,
    selectedOperationStatus?: any,
    currentPage?: number,
    pageSize?: number
  ) => {
    const cursor: PaginationCursorType = createCursor(
      isFilterChange,
      isPageSizeChanged,
      currentPageSize,
      search,
      selectedSearchFilterType,
      selectedTransactionType,
      selectedOperationStatus,
      currentPage,
      orderValues.column,
      orderValues.order
    );
    setIsPrevPage(false);

    setSearchValues({
      isFilterChange,
      isPageSizeChanged,
      pageSize: currentPageSize,
      search: search || '',
      selectedSearchFilterType,
      selectedTransactionType,
      selectedOperationStatus,
      currentPage: currentPage || defaultCurrentPage,
    });

    try {
      fetchData(cursor, pageSize || currentPageSize, true);
    } catch (err) {
      setError(true);
      setErrorMessage('error');
      setCurrentPage(1);
    }
  };

  const orderByHandler = async (
    isFilterChange: boolean,
    isPageSizeChanged: boolean,
    pageSize: number,
    currentPage: number,
    column: string,
    order: string
  ) => {
    const cursor: PaginationCursorType = createCursor(
      isFilterChange,
      isPageSizeChanged,
      pageSize,
      searchValues.search,
      searchValues.selectedSearchFilterType,
      searchValues.selectedTransactionType,
      searchValues.selectedOperationStatus,
      searchValues.currentPage,
      column,
      order
    );

    setOrderValues({ column, order });

    try {
      fetchData(cursor, pageSize || currentPageSize, isFilterChange);
    } catch (err) {
      setError(true);
      setErrorMessage('error');
    }
  };

  const changePageSizeHandler = async (
    isFilterChange: boolean,
    isPageSizeChanged: boolean,
    pageSize: number,
    currentPage: number
  ) => {
    const cursor: PaginationCursorType = createCursor(
      isFilterChange,
      isPageSizeChanged,
      pageSize,
      searchValues.search,
      searchValues.selectedSearchFilterType,
      searchValues.selectedTransactionType,
      searchValues.selectedOperationStatus,
      currentPage,
      orderValues.column,
      orderValues.order
    );

    try {
      fetchData(cursor, pageSize || currentPageSize);
    } catch (err) {
      setError(true);
      setErrorMessage('error');
    }
  };

  const resetFiltersHandler = (value: any) => {
    setOrderValues({ column: '', order: '' });
    setSortOrder(0);

    try {
      fetchData(defaultCursor);
    } catch (err) {
      setError(true);
      setErrorMessage('error');
    }

    setCursorKeyCollection([firstPageCursor]);
    setCurrentPage(1);
  };

  useEffect(() => {
    try {
      fetchData().catch((err) => {
        setError(true);
        setErrorMessage('error');
      });
    } catch (err) {
      setError(true);
      setErrorMessage('error');
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataAndSetCooldown = () => {
    setIsPrevPage(false);
    setCurrentPage(1);
    searchButtonHandler(
      searchValues.isFilterChange,
      searchValues.isPageSizeChanged,
      searchValues.search,
      searchValues.selectedSearchFilterType,
      searchValues.selectedTransactionType,
      1,
      searchValues.pageSize
    );
    setCoolDown();
  };

  return (
    <div>
      <ExportFileModal
        visible={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        downloadFile={(dateRange: DateRangeType) => downloadFile(dateRange)}
      />
      <div className={styles.pageContainer}>
        <Header />
        <div>
          <div className={styles.mainSection}>
            <SideBar />
            <div className={styles.mainSectionWorkArea}>
              <div>
                <div
                  className={`table-layout ${
                    isCooldownTime ? 'adjustTableHeader' : ''
                  }`}
                >
                  <div className={styles.tableHeader}>
                    <h1 className={styles.headerText}>
                      {t('transactionsHistory.title')}
                    </h1>
                    <Button
                      className={styles.exportButton}
                      icon="pi pi-download"
                      label="Export"
                      onClick={() => setShowDownloadModal(true)}
                    />
                  </div>
                  <div className={styles.tableWorkingArea}>
                    <Table
                      fetchDataAndSetCooldown={fetchDataAndSetCooldown}
                      searchButtonHandler={searchButtonHandler}
                      resetFiltersHandler={resetFiltersHandler}
                      changePageSizeHandler={changePageSizeHandler}
                      orderByHandler={orderByHandler}
                      isCooldownTime={isCooldownTime}
                      columnHeaders={columnHeaders}
                      tableData={transactionData}
                      loading={isLoading}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      currentPageSize={currentPageSize}
                      setCurrentPageSize={setCurrentPageSize}
                      isPrevPage={isPrevPage}
                      isNextPage={isNextPage}
                      setPrevPage={setIsPrevPage}
                      pagesAmount={pagesAmount}
                      defaultCurrentPage={defaultCurrentPage}
                      resultAmount={resultAmount}
                      pageSizes={rowsPerPageOptions}
                      setSortOrder={setSortOrder}
                      sortOrder={sortOrder}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardReports;
