import React from 'react';

import { FaDownload, FaFile } from 'react-icons/fa6';
import styles from './Settings.module.scss';

type CardSettingsProps = {
  title: string;
  description: string;
  downloadUrl: string;
};

const CardSettings: React.FC<CardSettingsProps> = ({
  title,
  description,
  downloadUrl,
}) => {
  return (
    <a
      href={downloadUrl}
      download
      className={styles.cardLink}
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.containerCards}>
        <div className={styles.containerIconDow}>
          <div className={styles.iconFile}>
            <FaFile color=" #00baff" size={25} />
          </div>
          <div className={styles.containerConten}>
            <div className={styles.settingsLabelEmail}>{title}</div>
            <div className={styles.settingsValueEmail}>{description}</div>
          </div>
        </div>
        <div>
          <FaDownload size={28} color=" #00baff" />
        </div>
      </div>
    </a>
  );
};

export default CardSettings;
