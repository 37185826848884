import { Suspense, useEffect } from 'react';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import { ProgressSpinner } from 'primereact/progressspinner';

import { MainContextProvider } from './contexts/mainProvider';
import { DisbursementProvider } from './contexts/DisbursementContext';
import { FormDataProvider } from './contexts/FormDataContext';

import Router from './router/Router';

const authTokenName = 'authToken';

const App = () => {
  return (
    <MainContextProvider>
      <FormDataProvider>
        <DisbursementProvider>
          <Router />
        </DisbursementProvider>
      </FormDataProvider>
    </MainContextProvider>
  );
};

const Wrapped = () => {
  return (
    <Suspense
      fallback={
        <div className="flex justify-content-center align-items-center h-screen w-screen">
          <ProgressSpinner aria-label="Loading" />
        </div>
      }
    >
      <App />
    </Suspense>
  );
};

export default Wrapped;
