import { isAxiosError } from 'axios';
import { axiosAuth } from '@httpClients';

interface CodeResponse {
  data: any;
}

export const sendUsername = async (username: string) => {
  try {
    await axiosAuth.post('/forgot-password', { username });
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(error.response?.data);
    }
    throw error;
  }
};

export const sendCode = async (securityCode: string, username: string) => {
  let atoken;

  try {
    const req = await axiosAuth.post<CodeResponse>('/sign-in-security-code', {
      username,
      security_code: securityCode,
    });
    if (req.data) {
      atoken = req.data.data.access_token;
    }
    return atoken;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(error.response?.data);
    }
    throw error;
  }
};
