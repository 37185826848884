import { FormHeader } from '@components/FormHeader/FormHeader';
import { Visa } from '@components/Visa/Visa';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import style from './EmailValidationSuccess.module.scss';

const EmailValidationError: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={style.containerPage}>
      <div className={style.containerData}>
        <div>
          <FormHeader />
        </div>
        <div className={style.containerText}>
          <div className={style.containerIcon}>
            <FontAwesomeIcon icon={faXmark} color="white" size="4x" />
          </div>
          <div className={style.container}>
            <div className={style.titleStyle}>
              {t('emailValidationError.title')}
            </div>
            <div className={style.containerInformation}>
              <div className={style.subtitleStyle}>
                {t('emailValidationError.subtitle')}
              </div>
              <div className={style.stylesText}>
                {' '}
                {t('emailValidationError.text')}
              </div>
            </div>
            <div className={style.containerDescription}>
              {t('emailValidationError.description')}
            </div>
          </div>
        </div>

        <span className={style.divider} />

        <div>
          <h5 className={style.containerForSecurity}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </h5>
        </div>
        <div className={style.visaLogo}>
          <Visa />
        </div>
      </div>
    </div>
  );
};
export default EmailValidationError;
