import { axiosFuex } from '@httpClients';

export const access = async (
  userToken: string
): Promise<{ success: boolean; data?: any }> => {
  try {
    const path = `/recipients/validate-email`;

    const response = await axiosFuex.patch(path, { userToken });
    const { data, status_code: status } = response.data;

    if (status === 200) {
      return { success: true, data };
    }
    return { success: false };
  } catch (error) {
    console.error('Error:', error);
    return { success: false };
  }
};
