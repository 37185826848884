import React from 'react';
import { Link } from 'react-router-dom';

const Landing = () => {
  return (
    <div>
      <h1 className="text-white">Landing Page</h1>
      <h2 className="text-white"> Sign in please, in order to access</h2>
      <Link className="text-blue-200" to="/">
        {' '}
        Get Access{' '}
      </Link>
    </div>
  );
};

export default Landing;
