import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { access } from '@services/access-redirect';
import { ListPages } from '@enums/enums';

const Access = (props: any) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get('userToken') || '';
  useEffect(() => {
    const validateEmail = async () => {
      const result = await access(userToken);

      if (result.success) {
        navigate(ListPages.EmailValidationSuccess);
      } else {
        navigate(ListPages.EmailValidationError);
      }
    };
    if (userToken) {
      validateEmail();
    } else {
      navigate(ListPages.EmailValidationError);
    }
  }, [userToken, navigate]);
  return null;
};

export default Access;
