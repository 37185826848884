import { isAxiosError } from 'axios';
import { axiosFuex } from '@httpClients';
import { ListPages } from '@enums/enums';
import { B2cAuthErrorTypes } from '@enums/B2cAuthErrorTypesEnum';
import { msalInstance } from '../msalconfig';

export const uploadRecipientsBulk = async (file: File): Promise<any> => {
  try {
    const path = '/recipients/bulk';
    const authToken = sessionStorage.getItem('authToken');
    const authHeader = `Bearer ${authToken}`;

    const formData = new FormData();
    formData.append('RecipientsBulk', file);

    const response = await axiosFuex.post(path, formData, {
      headers: {
        Authorization: authHeader,
        'Content-Type': 'multipart/form-data',
      },
    });

    const affectedRows = response.data.data?.affected_rows;

    if (response.data.errors) {
      throw new Error(response.data.errors);
    }
    return { status: response.status, affectedRows, data: response.data };
  } catch (error) {
    if (
      isAxiosError(error) &&
      (error.response?.status === 401 || error.response?.status === 403)
    ) {
      sessionStorage.clear();
      try {
        await msalInstance.logoutRedirect({
          postLogoutRedirectUri: window.location.origin + ListPages.Login,
        });
      } catch (logoutError) {
        console.error('Error during logout:', logoutError);
        window.location.href = `${ListPages.Login}?error=${B2cAuthErrorTypes.MissingToken}`;
      }
      throw new Error('Unauthorized access, redirecting to login.');
    } else {
      throw error;
    }
  }
};
