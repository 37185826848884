const NotFound = () => {
  return (
    <div className="flex flex-column justify-content-center align-items-center">
      <h1>Oops!</h1>
      <h3>This page doesn&apos;t exist</h3>
    </div>
  );
};

export default NotFound;
