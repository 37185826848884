import axios from 'axios';

const baseUrl = process.env['REACT_APP_BASE_URL'];

const apiVersion = process.env['REACT_APP_API_VERSION'];

const fuexBaseUrl = process.env['REACT_APP_FUEX_CONNECT_BASE_URL'];

if (!baseUrl) {
  throw new Error('REACT_APP_BASE_URL Environment Variable is not defined');
}

if (!apiVersion) {
  throw new Error('REACT_APP_API_VERSION Environment Variable is not defined');
}

export const axiosAuth = axios.create({
  baseURL: `${baseUrl}/${apiVersion}/auth`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
  timeoutErrorMessage: 'No hemos podido cargar datos esenciales',
});

export const axiosFuex = axios.create({
  baseURL: fuexBaseUrl,
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
  timeoutErrorMessage: "Escential data couldn't be loaded",
});
