import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import './ErrorMessage.scss';

type ErrorMessageProps = {
  message: string;
  detail: string;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, detail }) => {
  return (
    <div className="error-message-container">
      <div className="error-message-icon">
        <FontAwesomeIcon icon={faTriangleExclamation} color="#be0001" />
      </div>
      <div className="error-message-text">
        <strong>{message}</strong>
        <div className="detail">{detail}</div>
      </div>
    </div>
  );
};

export default ErrorMessage;
