import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { statusLabels } from '@enums/statusActionRecipientsEnum';
import { TagStateRecipientsButtonType } from '@components/Table/types';
import { FaTrash } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { quickActions } from '@services/quick-actions';
import styles from './ButtonReSendEmail.module.scss';

type TagStateRecipientsType = {
  action: 'reSendEmail' | 'removeCard';
  email: string;
  state: 'pending_activation' | 'capturing_data' | 'active';
};

export const ButtonReSendEmail = ({
  status,
  fetchDataAndSetCooldown,
}: {
  status: TagStateRecipientsType;
  fetchDataAndSetCooldown: (
    cursor: PaginationRecipientsCursorType,
    pageSize: number,
    isInitialLoad: boolean
  ) => void;
}) => {
  const { t } = useTranslation();

  const statusLabel = statusLabels?.find((e) => status.action === e.code);
  const toast = useRef<Toast>(null);

  const handleClick = async () => {
    const cursor = {
      filters: {},
    };
    const pageSize = 15;
    try {
      const body = {
        email: status.email,
        status: status.state,
      };

      const response = await quickActions(body);

      if (!toast.current) return;

      if (response.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: t('recipientsActions.success'),
          life: 3000,
        });
        fetchDataAndSetCooldown(cursor, pageSize, true);
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Warn',
          detail: t('recipientsActions.error'),
          life: 3000,
        });
      }
    } catch (error) {
      if (!toast.current) return;

      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: t('recipientsActions.error'),
        life: 3000,
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  const iconMap = {
    reSendEmail: <FontAwesomeIcon icon={faEnvelope} color="black" />,
    removeCard: <FaTrash size={16} color="red" />,
  };

  return (
    <>
      <Toast ref={toast} position="bottom-right" />

      <div
        className={`${styles.statusTypeLabel} 
      ${styles[`${statusLabel?.color}Border` || 'black']}`}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        {iconMap[status.action] || (
          <i className={`${styles.icon} ${styles.black}`} />
        )}
        <span className={styles[statusLabel?.color || 'black']}>
          {[statusLabel?.name]}
        </span>
      </div>
    </>
  );
};
