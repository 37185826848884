import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './SuccessMessage.scss';

type SucccessMessageProps = {
  message: string;
  detail: string;
};

const SuccessMessage: React.FC<SucccessMessageProps> = ({
  message,
  detail,
}) => {
  return (
    <div className="success-message-container">
      <div className="success-message-icon">
        <FontAwesomeIcon icon={faCheck} color="#039b25" />
      </div>
      <div className="success-message-text">
        <strong>{message}</strong>
        <div className="detail">{detail}</div>
      </div>
    </div>
  );
};

export default SuccessMessage;
