import { PublicClientApplication, Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env['REACT_APP_CLIENT_ID'] || 'default',
    authority: process.env['REACT_APP_AUTHORITY'],
    redirectUri: `${window.location.origin}/b2c-redirect`,
    knownAuthorities: ['demoswitchb2c.b2clogin.com'],
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
