import { statusLabels } from '@enums/statusRecipientsEnum';
import { FaCircleCheck, FaClock, FaShieldHalved } from 'react-icons/fa6';
import styles from './TagStateRecipients.module.scss';

type TagStateRecipientsType = {
  state: 'pendingactivation' | 'capturingdata' | 'active';
};

export const TagStateRecipients = (props: {
  status: TagStateRecipientsType;
}) => {
  const { status } = props;
  const statusLabel = statusLabels?.find((e) => status.state === e.code);

  const iconMap = {
    pendingactivation: <FaShieldHalved size={16} color="#167fe8" />,
    capturingdata: <FaClock size={16} color="#f68430" />,
    active: <FaCircleCheck size={14} color="#05b920" />,
  };

  return (
    <div
      className={`${styles.statusTypeLabel} 
      ${styles[`${statusLabel?.color}Border` || 'black']}`}
    >
      {iconMap[status.state] || (
        <i className={`${styles.icon} ${styles.black}`} />
      )}
      <span className={styles[statusLabel?.color || 'black']}>
        {[statusLabel?.name]}
      </span>
    </div>
  );
};
