import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { sendCode } from '@services/forgot-password';
import { ActionTypes, ListPages } from '@enums/enums';
import { CodeItem } from '@interfaces/interfaces';
import styles from './StepTwo.module.scss';
import { MainContext } from '../../../contexts/mainContext';

const StepTwo = () => {
  const [showInfo, setShowInfo] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { dispatch, state } = useContext(MainContext);
  const [code, setCode] = useState<CodeItem[]>([
    { value: '', id: 0 },
    { value: '', id: 1 },
    { value: '', id: 2 },
    { value: '', id: 3 },
    { value: '', id: 4 },
    { value: '', id: 5 },
  ]);
  const { t } = useTranslation();
  const inputsRef = useRef<Array<React.RefObject<HTMLInputElement>>>(
    Array(6)
      .fill(null)
      .map(() => createRef())
  );
  const navigate = useNavigate();

  let inputFocus: HTMLInputElement | null = null;

  useEffect(() => {
    if (state.errorStep2) {
      setError(true);
      if (state.errorMessage) {
        setErrorMessage(state.errorMessage);
      }
    }
  }, [state.errorStep2, state.errorMessage]);

  const handleFocus = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    let nextIndex = index + 1;
    let previousIndex = index - 1;

    if (event.key === 'Backspace') {
      previousIndex = previousIndex >= 0 ? previousIndex : 0;
      const input = inputsRef && inputsRef.current[previousIndex].current;

      if (!code[index].value && input) {
        inputFocus = input;
        input.focus();
      }
    } else {
      nextIndex =
        nextIndex >= inputsRef.current.length
          ? inputsRef.current.length - 1
          : nextIndex;
      const input = inputsRef && inputsRef.current[nextIndex].current;
      if (input) {
        inputFocus = input;
      }
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (code[index].value) {
      const newCode = code.map((val, idx) => {
        if (index === idx) {
          return { value: event.target.value, id: index };
        }
        return val;
      });
      setCode(newCode);
    }

    const newCode = [...code];
    if (newCode[index].value) {
      newCode[index].value = event.target.value.trim();
      return;
    }
    newCode[index].value = event.target.value;
    setCode(newCode);

    inputFocus?.focus();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      let securityCode = '';
      code.forEach((digit) => {
        securityCode += digit.value;
      });

      const atoken = await sendCode(securityCode, state.username);
      dispatch({
        type: ActionTypes.SetAToken,
        payload: atoken,
      });
      dispatch({
        type: ActionTypes.SendCode,
        payload: { state: 'success' },
      });
      dispatch({
        type: ActionTypes.SetForgotPasswordFlow,
        payload: true,
      });
      return navigate(ListPages.ChangePass, { replace: true });
    } catch (err) {
      return dispatch({
        type: ActionTypes.SendCode,
        payload: { state: 'error' },
      });
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const numberPasted = e.clipboardData.getData('text');
    const separated = numberPasted.split('');

    if (separated.length > 6) {
      return;
    }

    const newCode = separated.map((val, idx) => {
      return { value: val, id: idx };
    });

    setCode(newCode);

    inputFocus?.focus();
  };

  return (
    <div>
      <p className="flex justify-content-start text-3xl font-bold my-3 text-black-alpha-90 title">
        {t('forgotPassword.stepTwo.title')}
      </p>
      <p className="text-sm">{t('forgotPassword.stepTwo.subtitle')}</p>
      <p className="text-xs">{t('forgotPassword.stepTwo.verificationCode')}</p>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-row justify-content-between">
          {code.map((digit) => (
            <InputText
              key={digit.id}
              onPaste={(e) => handlePaste(e)}
              onChange={(e) => handleChange(e, digit.id)}
              onKeyDown={(e) => handleFocus(e, digit.id)}
              ref={inputsRef.current[digit.id]}
              className={styles.codeInput}
              value={digit.value}
              maxLength={1}
            />
          ))}
        </div>

        <Button
          type="submit"
          className="w-full justify-content-center mt-5 p-button-raised p-button-success customMainButton"
          disabled={!code.every(({ value }) => value !== '')}
        >
          {t('forgotPassword.stepTwo.continueButton')}
        </Button>
        {error ? (
          <p
            key="message"
            className="text-xs m-0 ml-2 text-red-600 text-center mt-2"
          >
            {errorMessage}
          </p>
        ) : null}
      </form>
      <div
        role="button"
        onClick={() => setShowInfo(!showInfo)}
        className="text-sm text-blue-900 font-bold mt-2 cursor-pointer"
        aria-hidden="true"
      >
        {t('forgotPassword.stepTwo.notReceived')}
      </div>
      {showInfo && (
        <div className="text-sm">
          <p>{t('forgotPassword.stepTwo.tryTheFollowing')}</p>
          <ul className="pl-3 gap-3">
            <li>{t('forgotPassword.stepTwo.list1')}</li>
            <li className="font-bold text-blue-900">
              {t('forgotPassword.stepTwo.list2')}
            </li>
            <li>
              {t('forgotPassword.stepTwo.list3.light')}
              <span className="font-bold text-blue-900">
                {' '}
                {t('forgotPassword.stepTwo.list3.dark')}
              </span>
            </li>
            <li>
              {t('forgotPassword.stepTwo.list4.light')}
              <span className="font-bold text-blue-900">
                {t('forgotPassword.stepTwo.list4.dark')}
              </span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default StepTwo;
