import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import styles from './ConfirmationModal.module.scss';

type ConfirmationModalPropsType = {
  visible: any;
  onHide: any;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  headerText: string;
  contentText: string;
  confirmText: string;
  cancelText: string;
};

export const ConfirmationModal = ({
  onHide,
  visible,
  onConfirm,
  headerText,
  confirmText,
  contentText,
  cancelText,
}: ConfirmationModalPropsType) => {
  const { t } = useTranslation();

  return (
    <Dialog
      dismissableMask
      draggable={false}
      visible={visible}
      onHide={onHide}
      closeOnEscape
      headerClassName={styles.modalTitle}
      header={t(headerText)}
      className={styles.modal}
      footer={
        <div className={styles.buttonContainer}>
          <Button onClick={onHide} className={styles.cancelButton}>
            <p>{t(cancelText)}</p>
          </Button>
          <Button onClick={onConfirm} className={styles.confirmButton}>
            <p>{t(confirmText)}</p>
          </Button>
        </div>
      }
    >
      <p className={styles.modalText}>{t(contentText)}</p>
    </Dialog>
  );
};
