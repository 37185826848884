export const statusLabels = [
  {
    code: 'reSendEmail',
    name: 'Re-Send Email',
    color: 'black',
  },
  {
    code: 'removeCard',
    name: 'Remove Card',
    color: 'red',
  },
];
