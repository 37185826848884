/* eslint-disable prettier/prettier */
import { isAxiosError } from 'axios';
import { axiosFuex } from '@httpClients';
import { ListPages } from '@enums/enums';
import { B2cAuthErrorTypes } from '@enums/B2cAuthErrorTypesEnum';
import { DashboardReportType } from '../types/responses/dashboard-report';
import { msalInstance } from '../msalconfig';

export const fetchDashboardReportData = async (
  cursor: PaginationCursorType | DownloadCursorType,
  pageSize?: number
): Promise<DashboardReportType | DownloadResponse | undefined> => {
  let data;
  try {
    const authToken = sessionStorage.getItem('authToken');
    const authHeader = `Bearer ${authToken}`;
    const url = `/transactions-history${
      pageSize ? `?pageSize=${pageSize}` : ''
    }`;
    const stringifyBody = JSON.stringify(cursor);

    const res = await axiosFuex.post(url, stringifyBody, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
      },
    });

    if (res?.data.data) {
      const responseData = res.data.data;
      const transformedData: DashboardReportType = {
        data: responseData?.data || [],
        totalOnPage: responseData?.totalOnPage || 0,
        totalResults: responseData?.totalResults || 0,
        lastEvaluatedKey: responseData?.lastEvaluatedKey || null,
      };

      data = transformedData;
    }
    return data;
  } catch (error) {
    if (
      isAxiosError(error) &&
      (error.response?.status === 401 || error.response?.status === 403)
    ) {
      sessionStorage.clear();
      try {
        await msalInstance.logoutRedirect({
          postLogoutRedirectUri: window.location.origin + ListPages.Login,
        });
      } catch (logoutError) {
        console.error('Error during logout:', logoutError);
        window.location.href = `${ListPages.Login}?error=${B2cAuthErrorTypes.MissingToken}`;
      }
      throw new Error('Unauthorized access, redirecting to login.');
    } else {
      throw error;
    }
  }
};
