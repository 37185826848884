import headerForm from '../../assets/form_header.svg';
import styles from './FormHeader.module.scss';

export const FormHeader = () => {
  const headerPath = headerForm as unknown as string;

  return (
    <img
      alt="zenus bank logo header"
      className={styles.image}
      src={headerPath}
    />
  );
};
