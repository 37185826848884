import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Calendar, CalendarValueType } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Divider } from 'primereact/divider';

import styles from './ExportFileModal.module.scss';

type ExportFileModalPropsType = {
  visible: any;
  onHide: any;
  downloadFile: any;
};

export const ExportFileModal = ({
  onHide,
  visible,
  downloadFile,
}: ExportFileModalPropsType) => {
  const { t } = useTranslation();

  const dateFormat = t('dateFormat');
  const todayDate = new Date();
  const lastDate = new Date();

  const minDate = new Date();
  minDate.setDate(todayDate.getDate() - 90);

  lastDate.setDate(todayDate.getDate() - 1);

  // Dates states
  const [startDate, setStartDate] = useState<CalendarValueType>(lastDate);
  const [endDate, setEndDate] = useState<CalendarValueType>(lastDate);

  // Logic states
  const [filterByRange, setFilterByRange] = useState<boolean>(false);
  const [datesError, setDatesError] = useState<boolean>(false);

  function handleChange(useFilterRange: boolean) {
    setStartDate(lastDate);
    setEndDate(lastDate);
    setFilterByRange(useFilterRange);
    setDatesError(false);
  }

  const closeAndResetModal = () => {
    handleChange(false);
    setDatesError(false);
    onHide();
  };

  const parseDate = (date: Date) => {
    const newDate = new Date(date);
    // Months starts from 0
    const year = date.getFullYear();
    // Needed for complying with BE needs
    const month = `0${newDate.getMonth() + 1}`.slice(-2);
    const day = `0${newDate.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  const handleConfirm = () => {
    let dateRange = {
      startDate: parseDate(minDate as Date),
      endDate: parseDate(todayDate as Date),
    };
    if (filterByRange) {
      if (!!startDate && !!endDate && startDate <= endDate) {
        setDatesError(false);
        dateRange = {
          startDate: parseDate(startDate as Date),
          endDate: parseDate(endDate as Date),
        };
        downloadFile(dateRange);
        closeAndResetModal();
      } else {
        setDatesError(!!startDate && !!endDate);
      }
    } else {
      downloadFile(dateRange);
      closeAndResetModal();
    }
  };

  return (
    <Dialog
      draggable={false}
      visible={visible}
      onHide={closeAndResetModal}
      closeOnEscape
      headerClassName={styles.modalTitle}
      header={t('transactionsHistory.downloadModal.title')}
      className={styles.modal}
      footer={
        <div className={styles.inputContainer}>
          <Button onClick={closeAndResetModal} className={styles.cancelButton}>
            <p>{t('transactionsHistory.downloadModal.cancelButton')}</p>
          </Button>
          <Button onClick={handleConfirm} className={styles.confirmButton}>
            <p>{t('transactionsHistory.downloadModal.exportButton')}</p>
          </Button>
        </div>
      }
    >
      <div>
        <p className={styles.modalText}>
          {t('transactionsHistory.downloadModal.description')}
        </p>
        <p className={styles.filterText}>
          {t('transactionsHistory.downloadModal.filterText')}
        </p>
        <div className={styles.radiobuttonContainer}>
          <div>
            <RadioButton
              className={styles.radiobutton}
              inputId="filterRangeFalse"
              name="filterRangeFalse"
              value="false"
              onChange={() => handleChange(false)}
              checked={filterByRange === false}
            />
            <label
              htmlFor="filterRangeFalse"
              className={styles.radiobuttonText}
            >
              {t('transactionsHistory.downloadModal.radiobuttonFalse')}
            </label>
          </div>
          <div>
            <RadioButton
              inputId="filterRangeTrue"
              name="filterRangeTrue"
              value="true"
              onChange={() => handleChange(true)}
              checked={filterByRange === true}
            />
            <label htmlFor="filterRangeTrue" className={styles.radiobuttonText}>
              {t('transactionsHistory.downloadModal.radiobuttonTrue')}
            </label>
          </div>
        </div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.inputContainer}>
        <div>
          <p className={styles.calendarText}>From</p>
          <Calendar
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            dateFormat={dateFormat}
            minDate={minDate}
            maxDate={todayDate}
            disabled={!filterByRange}
            showIcon
            onFocus={() => setDatesError(false)}
            icon={<FontAwesomeIcon icon={faCalendar as IconProp} />}
            className={!startDate || datesError ? 'p-invalid' : ''}
          />
          <span className={styles.exampleText}>
            {t('transactionsHistory.downloadModal.calendarExample')}
          </span>
          {(!startDate || datesError) && (
            <span className={styles.dateError}>
              {!startDate && t('transactionsHistory.downloadModal.noDateError')}
              {datesError &&
                t('transactionsHistory.downloadModal.invalidDateError')}
            </span>
          )}
        </div>
        <div>
          <p className={styles.calendarText}>To</p>
          <Calendar
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            dateFormat={dateFormat}
            minDate={minDate}
            maxDate={todayDate}
            disabled={!filterByRange}
            showIcon
            onFocus={() => setDatesError(false)}
            icon={<FontAwesomeIcon icon={faCalendar as IconProp} />}
            className={!endDate || datesError ? 'p-invalid' : ''}
          />
          <span className={styles.exampleText}>
            {t('transactionsHistory.downloadModal.calendarExample')}
          </span>
          {(!endDate || datesError) && (
            <span className={styles.dateError}>
              {!endDate && t('transactionsHistory.downloadModal.noDateError')}
              {datesError &&
                t('transactionsHistory.downloadModal.invalidDateError')}
            </span>
          )}
        </div>
      </div>
    </Dialog>
  );
};
