import { axiosFuex } from '@httpClients';
import { isAxiosError } from 'axios';
import { ListPages } from '@enums/enums';
import { B2cAuthErrorTypes } from '@enums/B2cAuthErrorTypesEnum';
import { CreateDisbursementResponseType } from '../types/responses/create-disbursement';
import { DisbursementType } from '../types/DisbursementType';

export const createDisbursement = async (
  body: DisbursementType
): Promise<CreateDisbursementResponseType> => {
  try {
    const path = '/disbursement/single';
    const raw = JSON.stringify(body);
    const authToken = sessionStorage.getItem('authToken');
    const authHeader = `Bearer ${authToken}`;

    const response = await axiosFuex.post(path, raw, {
      headers: {
        Authorization: authHeader,
      },
    });

    const { errors, status_code: statusCode, data } = response.data;

    if (errors) throw new Error(errors);
    if (statusCode !== 200) throw new Error('Error creating disbursement');

    return {
      statusCode,
      data,
      errors,
    };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        window.location.href = `${ListPages.Login}?error=${B2cAuthErrorTypes.MissingToken}`;
        throw new Error('Unauthorized access, redirecting to login.');
      } else {
        throw new Error(error.message);
      }
    } else {
      throw error;
    }
  }
};
