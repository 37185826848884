import React from 'react';
import { Button } from 'primereact/button';
import { FaArrowsRotate } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { decodeToken } from 'react-jwt';
import { JwtAuthToken } from '@interfaces/interfaces';
import Header from '@components/Header/Header';
import SideBar from '@components/SideBar/SideBar';
import CardSettings from './CardSettings';
import styles from './Settings.module.scss';
import Footer from './Footer';
import { msalInstance as instance } from '../../msalconfig';

type SettingsProps = {
  // pros
};
const documentSettings = [
  {
    title: 'E-Sign',
    description: 'Check your contract details',
    downloadUrl: 'https://www.africau.edu/images/default/sample.pdf',
  },
  {
    title: 'Privacy policy',
    description: 'Check our policy',
    downloadUrl: 'https://www.africau.edu/images/default/sample.pdf',
  },
  {
    title: 'Terms & Conditions',
    description: 'See all the terms & Conditions',
    downloadUrl: 'https://www.africau.edu/images/default/sample.pdf',
  },
  {
    title: 'Merchant Agreement',
    description: 'Read and verify the full agreement',
    downloadUrl: 'https://www.africau.edu/images/default/sample.pdf',
  },
];

const Settings: React.FC<SettingsProps> = () => {
  const authTokenName = 'authToken';
  let authToken = '';
  authToken = sessionStorage.getItem(authTokenName) || '{}';
  const { name, emails }: JwtAuthToken =
    decodeToken(authToken) || ({} as JwtAuthToken);
  const { t } = useTranslation();

  const forgotRedirect: string =
    process.env['REACT_APP_AUTHORITY_FORGOT']?.toString() ?? '';

  const navigateToChangePass = () => {
    instance.loginRedirect({
      scopes: ['openid'],
      nonce: 'defaultNonce',
      authority: forgotRedirect,
    });
  };

  return (
    <div>
      <Header />
      <div className={styles.mainSection}>
        <SideBar />
        <div className={styles.containerData}>
          <div className={styles.mainSectionWorkArea}>
            <div className="settings-section">
              <h2 className={styles.title}> {t('settings.title')}</h2>
            </div>
            <div className={styles.userSettings}>
              <div className={styles.settingsSection}>
                <div className={styles.containerConten}>
                  <div className={styles.settingsLabel}>
                    {t('settings.yourName')}
                  </div>
                  <div className={styles.settingsValue}>{name}</div>
                </div>
                <span className={styles.separator} />
                <div className={styles.containerConten}>
                  <div className={styles.settingsLabelEmail}>
                    {t('settings.email')}
                  </div>
                  <div className={styles.settingsValueEmail}>{emails[0]}</div>
                </div>
                <span className={styles.separator} />
                <div className={styles.containerConten}>
                  <div className={styles.settingsLabelEmail}>
                    {t('settings.password')}
                  </div>
                  <div className={styles.settingsAction}>
                    <div>
                      <Button
                        label="Change Password"
                        className={styles.buttonsChange}
                        onClick={navigateToChangePass}
                      />
                    </div>
                    <div className={styles.styleIcon}>
                      <FaArrowsRotate />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="settings-section">
              <div className={styles.containerDocuments}>
                <h2 className={styles.documents}> {t('settings.documents')}</h2>
              </div>
              <div className={styles.containerCardSettings}>
                {documentSettings.map((setting) => (
                  <div className={styles.settingsCard} key={setting.title}>
                    <CardSettings
                      key={setting.title}
                      title={setting.title}
                      description={setting.description}
                      downloadUrl={setting.downloadUrl}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
