import { Card } from 'primereact/card';
import React from 'react';
import { Logo } from '@components/Logo/Logo';
import { MainCardProps } from '@interfaces/interfaces';

const MainCard: React.FC<MainCardProps> = ({ children }) => {
  return (
    <Card className="mainCard">
      <div>
        <Logo />
      </div>
      <div className="divider" />
      {children}
    </Card>
  );
};

export default MainCard;
