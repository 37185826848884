import { isAxiosError } from 'axios';
import { axiosAuth } from '@httpClients';

export const sendPasswords = async (
  currentPassword: string,
  password: string,
  passwordConfirmation: string,
  atoken: string
) => {
  let bodyParams;
  if (currentPassword) {
    bodyParams = {
      previousPassword: currentPassword,
      password,
      passwordConfirmation,
    };
  } else {
    bodyParams = { password, passwordConfirmation };
  }
  try {
    const config = {
      headers: { Authorization: `Bearer ${atoken}` },
    };
    await axiosAuth.post(
      '/change-password',
      {
        ...bodyParams,
      },
      config
    );
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(error.response?.data?.errors[0].message);
    }
    throw error;
  }
};
