export const statusLabels = [
  {
    code: 'capturingdata',
    name: 'Capturing Data',
    icon: 'pi pi-clock',
    color: 'orange',
  },
  {
    code: 'active',
    name: 'Active',
    icon: 'pi pi-check',
    color: 'green',
  },
  {
    code: 'pendingactivation',
    name: 'Pending Activation',
    icon: 'pi pi-times',
    color: 'blue',
  },
];
