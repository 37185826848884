import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListPages } from '@enums/enums';
import { msalInstance } from '../../msalconfig';
import MainCard from '../../features/MainCard';
import '@styles/main.scss';

const authTokenName = 'authToken';

const Logout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const logout = async () => {
      sessionStorage.removeItem(authTokenName);
      await msalInstance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin,
      });
      navigate(ListPages.Login, { replace: true });
    };
    logout();
  }, [navigate]);

  return (
    <MainCard>
      <div>
        <h1>{t('logout.message')}</h1>
      </div>
    </MainCard>
  );
};

export default Logout;
