import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { decodeToken } from 'react-jwt';
import { ListPages } from '@enums/enums';
import { validateToken } from '@services/validate-token';
import { JwtAuthToken } from '@interfaces/interfaces';
import { Button } from 'primereact/button';
import styles from './Header.module.scss';
import { Logo } from '../Logo/Logo';
import { UserMenu } from '../UserMenu/UserMenu';

const Header = () => {
  const authTokenName = 'authToken';
  let authToken = '';
  const navigate = useNavigate();
  authToken = sessionStorage.getItem(authTokenName) || '{}';

  const validateTokenHandler = useCallback(async () => {
    try {
      await validateToken(authToken);
    } catch (err) {
      navigate(ListPages.Login, { replace: true });
    }
  }, [authToken, navigate]);

  useEffect(() => {
    validateTokenHandler();
  }, [validateTokenHandler]);

  const { name }: JwtAuthToken = decodeToken(authToken) || ({} as JwtAuthToken);

  return (
    <ul className={styles.headerContainer}>
      <li className={styles.headerMenuContainer}>
        <div className={styles.containerLogo}>
          <Logo />
        </div>
      </li>
      <li className={styles.headerProfile}>
        <UserMenu userPic="" userName={name} />
      </li>
    </ul>
  );
};

export default Header;
