export const statusLabels = [
  {
    code: 'completed',
    name: 'Approved',
    icon: 'pi pi-check',
    color: 'green',
  },
  {
    code: 'error',
    name: 'Rejected',
    icon: 'pi pi-times',
    color: 'red',
  },
  {
    code: 'capturingdata',
    name: 'Capturing Data',
    icon: 'pi pi-check',
    color: 'orange',
  },
  {
    code: 'inprogress',
    name: 'In Process',
    icon: 'pi pi-times',
    color: 'violet',
  },
];
