import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import styles from './TableFooter.module.scss';

type PaginationControlType = {
  defaultCurrentPage: number;
  currentPage: any;
  setCurrentPage: any;
  resultAmount: number;
  pageSizes: Array<number>;
  searchPageHandler: any;
  changePageSizeHandler: any;
  isPrevPage: boolean;
  isNextPage: boolean;
  setPrevPage: any;
  resetOrderHandler: any;
  setCurrentPageSize: any;
  currentPageSize: number;
};

export const TableFooter = (props: PaginationControlType) => {
  const {
    defaultCurrentPage,
    currentPage,
    setCurrentPage,
    setCurrentPageSize,
    currentPageSize,
    pageSizes,
    resultAmount,
    searchPageHandler,
    changePageSizeHandler,
    isPrevPage,
    isNextPage,
    setPrevPage,
    resetOrderHandler,
  } = props;

  // Pagination States
  const [pagesAmount, setPagesAmount] = useState<number>();

  const calculatePageAmount = () => {
    if (currentPageSize && resultAmount) {
      const decimalDivision = resultAmount / currentPageSize;
      const truncDecimalDivision = Math.trunc(resultAmount / currentPageSize);
      const calculatedPagesAmount =
        decimalDivision !== truncDecimalDivision
          ? truncDecimalDivision + 1
          : decimalDivision;
      setPagesAmount(calculatedPagesAmount);
    }
  };

  const onChangePageSizeHandler = (value: string) => {
    setCurrentPageSize(+value);
    setCurrentPage(defaultCurrentPage);
    setPrevPage(false);
    resetOrderHandler(true);
    const isFilterChange = false;
    const isPageSizeChange = true;
    const pageSize = +value as number;
    changePageSizeHandler(isFilterChange, isPageSizeChange, pageSize, 1);
  };

  const onNextPageHandler = () => {
    const isFilterChange = false;
    const isPageSizeChange = false;
    const pageSize = currentPageSize;
    const searchedPage = currentPage + 1;

    setPrevPage(true);

    changePageSizeHandler(
      isFilterChange,
      isPageSizeChange,
      pageSize,
      searchedPage
    );

    setCurrentPage(searchedPage);
  };

  const onPrevPageHandler = () => {
    const isFilterChange = false;
    const isPageSizeChange = false;
    const pageSize = currentPageSize;
    const searchedPage = currentPage - 1;

    if (currentPage === 2) {
      setPrevPage(false);
    }

    changePageSizeHandler(
      isFilterChange,
      isPageSizeChange,
      pageSize,
      searchedPage
    );

    setCurrentPage(searchedPage);
  };

  useEffect(() => {
    calculatePageAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageSize, resultAmount]);

  return (
    <div className={styles.footerContainer}>
      <div className={styles.paginationControls}>
        {pagesAmount && (
          <div>
            Page: {currentPage} of {pagesAmount}
          </div>
        )}
        <span>Results per page</span>
        <Dropdown
          className={styles.filterComponent}
          value={currentPageSize}
          onChange={(e) => onChangePageSizeHandler(e.value)}
          options={pageSizes}
        />
        <Button
          onClick={onPrevPageHandler}
          className={styles.paginationButton}
          icon="pi pi-angle-left"
          disabled={!isPrevPage}
        />
        <Button
          onClick={onNextPageHandler}
          className={styles.paginationButton}
          icon="pi pi-angle-right"
          disabled={!isNextPage}
        />
      </div>
    </div>
  );
};
