import { useState } from 'react';
import styles from './NavBarItem.module.scss';

const NavBarItem = (props: any) => {
  const { name, selected } = props;
  const [isSelected, setIsSelected] = useState(selected || false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedHandler = (value: boolean): void => {
    setIsSelected(value);
  };

  return (
    <li
      className={
        isSelected
          ? `${styles.navBarItemSelected} ${styles.navBarItem}`
          : `${styles.navBarItem}`
      }
    >
      {name}
    </li>
  );
};

export default NavBarItem;
