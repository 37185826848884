import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '@components/Header/Header';
import { Button } from 'primereact/button';
import { ListPages } from '@enums/enums';
import SideBar from '@components/SideBar/SideBar';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import SuccessMessage from '@components/SuccessMessage/SuccessMesage';
import { uploadRecipientsBulk } from '@services/upload-csv';
import { FileUpload } from 'primereact/fileupload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import styles from '../DashboardReports/DashboardReports.module.scss';
import style from './UploadCsv.module.scss';
import './UploadCsv.scss';

const UploadCsv = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState({ show: false, message: '', detail: '' });
  const [success, setSuccess] = useState({
    show: false,
    message: '',
    detail: '',
  });

  const goBack = () => {
    navigate(ListPages.Recipients);
  };

  const uploadHandler = async (event: { files: File[] }) => {
    const file = event.files[0];
    const maxFileSize = 1048576;

    if (file && file.size <= maxFileSize) {
      try {
        const response = await uploadRecipientsBulk(file);

        if (response.status === 200) {
          setSuccess({
            show: true,
            message: `${response.affectedRows} ${t(
              'recipientsActions.successOne'
            )}`,
            detail: t('recipientsActions.successTwo'),
          });
        } else {
          setError({
            show: true,
            message: t('recipientsActions.errorOne'),
            detail: t('recipientsActions.errorTwo', { size: '1MB' }),
          });
        }
      } catch (err) {
        console.error('Error:', err);
        setError({
          show: true,
          message: t('recipientsActions.errorOne'),
          detail: t('recipientsActions.errorTwo', { size: '1MB' }),
        });
      }
    }
  };

  const downloadCsvHandler = () => {
    const link = document.createElement('a');
    link.href = 'RecipientsBulk.csv';
    link.setAttribute('download', 'RecipientsBulk.csv');
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | number;

    if (error.show) {
      timer = setTimeout(() => {
        setError({ ...error, show: false });
      }, 3000);
    }

    if (success.show) {
      timer = setTimeout(() => {
        setSuccess({ ...success, show: false });
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [error, success]);

  return (
    <div>
      <div className={styles.pageContainer}>
        <Header />
        <div className={styles.mainSection}>
          <SideBar />
          <div className={styles.mainSectionWorkArea}>
            <div className="upload">
              <div className="table-layout">
                <div className="container-button">
                  <Button
                    className="p-button-text"
                    onClick={goBack}
                    aria-label="Go Back"
                    icon={
                      <FontAwesomeIcon icon={faChevronLeft} color="170a33" />
                    }
                  >
                    {t('recipients.goback')}
                  </Button>
                </div>
                <div className={styles.tableHeader}>
                  <h1 className={styles.headerText}>
                    {t('recipients.titleUpload')}
                  </h1>
                </div>
              </div>
            </div>

            <div className={style.tableWorkingArea}>
              <div className="container-button-download">
                <Button
                  className="p-button-text downloadCsv"
                  onClick={downloadCsvHandler}
                  aria-label="Download CSV"
                  icon={<FontAwesomeIcon icon={faDownload} color="170a33" />}
                >
                  {t('recipients.download')}
                </Button>
              </div>
              <FileUpload
                customUpload
                uploadHandler={uploadHandler}
                accept=".csv"
              />
              {error.show && (
                <ErrorMessage message={error.message} detail={error.detail} />
              )}
              {success.show && (
                <SuccessMessage
                  message={success.message}
                  detail={success.detail}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCsv;
