import { statusLabels } from '@enums/statusEnum';
import { FaCircleCheck, FaClock, FaCircleXmark, FaGear } from 'react-icons/fa6';
import styles from './TagStatus.module.scss';

type StatusType = {
  operationStatus: 'completed' | 'error' | 'capturingdata' | 'inprogress';
};

export const TagStatus = (props: { status: StatusType }) => {
  const { status } = props;
  let statusLabel = statusLabels?.find(
    (e) => status.operationStatus === e.code
  );

  if (!statusLabel) {
    statusLabel = {
      code: 'capturingdata',
      name: 'Capturing Data',
      icon: 'pi pi-check',
      color: 'orange',
    };
  }

  const iconMap = {
    completed: <FaCircleCheck size={16} color="#02b920" />,
    error: <FaCircleXmark size={14} color="#fa2a2a" />,
    capturingdata: <FaClock size={16} color="#f68430" />,
    inprogress: <FaGear size={14} color="#a355ff" />,
  };

  return (
    <div
      className={`${styles.statusTypeLabel} 
      ${styles[`${statusLabel.color}Border`] || styles.black}`}
    >
      {iconMap[status.operationStatus] || iconMap.capturingdata}
      <span className={styles[statusLabel.color] || styles.black}>
        {statusLabel.name}
      </span>
    </div>
  );
};
