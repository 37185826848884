import { isAxiosError } from 'axios';
import { axiosAuth } from '@httpClients';

export const sendUsernameAndPassword = async (
  username: string,
  password: string
): Promise<boolean> => {
  try {
    const req = await axiosAuth.post('/sign-in', { username, password });
    if (req.data) {
      const atoken = req.data.data.access_token;
      return atoken;
    }
    return false;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(error.message);
    }
    throw error;
  }
};
