import { Route, Routes } from 'react-router-dom';
import AuthLayout from '@components/Layout/AuthLayout/AuthLayout';
import { RouterType } from '@interfaces/interfaces';
import DashboardReports from '../pages/DashboardReports/DashboardReports';
import { pagesAuth } from './pagesData';

const Router = () => {
  let changePasswordPage;

  const pageAuthRoutes = pagesAuth.map(({ path, element }: RouterType) => {
    if (path !== '/change-password') {
      return <Route key={path} path={`${path}`} element={element} />;
    }
    changePasswordPage = (
      <Route key={path} path={`${path}`} element={element} />
    );
    return null;
  });

  return (
    <Routes>
      <Route>{pageAuthRoutes}</Route>
      <Route>{changePasswordPage}</Route>
    </Routes>
  );
};

export default Router;
