import React from 'react';
import { Link } from 'react-router-dom';
import useNavigationGuards from '../hooks/useNavigationGuards';

const Home = () => {
  return (
    useNavigationGuards() || (
      <div>
        <h1 className="text-white">Home Page</h1>
        <h2 className="text-white">You are logged in</h2>
        <Link className="text-blue-200" to="/change-password">
          {' '}
          Change Password{' '}
        </Link>
      </div>
    )
  );
};

export default Home;
