import { MainAction, MainState } from '@interfaces/interfaces';
import { ActionTypes } from '../enums/enums';

import i18n from '../i18n';

export const mainReducer = (
  state: MainState,
  action: MainAction
): MainState => {
  const actionType = action.type;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionPayload: any = action.payload;

  switch (actionType) {
    case ActionTypes.SetAToken: {
      return { ...state, atoken: action.payload } as MainState;
    }
    case ActionTypes.SetForgotPasswordFlow: {
      return { ...state, forgotPasswordFlow: action.payload } as MainState;
    }
    case ActionTypes.SetPasswordChanged: {
      return { ...state, passwordChanged: action.payload } as MainState;
    }
    case ActionTypes.SetStep: {
      return { ...state, step: action.payload } as MainState;
    }
    case ActionTypes.SetLogin: {
      return { ...state, successPasswordChanged: true } as MainState;
    }
    case ActionTypes.SetChangePass: {
      return { ...state, successPasswordChanged: action.payload } as MainState;
    }
    case ActionTypes.SendUser: {
      if (actionPayload.state === 'success') {
        return {
          ...state,
          errorStep1: false,
          step: 2,
          username: actionPayload.username,
        };
      }
      return {
        ...state,
        errorStep1: true,
        errorMessage: i18n.t('forgotPassword.stepOne.errorMessage'),
      };
    }

    case ActionTypes.SendCode: {
      if (actionPayload.state === 'success') {
        return {
          ...state,
          errorStep1: false,
          step: 2,
        };
      }
      return {
        ...state,
        errorStep2: true,
        errorMessage: i18n.t('forgotPassword.stepTwo.errorMessage'),
      };
    }
    case ActionTypes.SetVerificationCode: {
      return { ...state, successCode: actionPayload } as MainState;
    }
    case ActionTypes.ResetErrors: {
      return {
        ...state,
        errorStep1: false,
        errorStep2: false,
      };
    }
    default:
      return state;
  }
};
