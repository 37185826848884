export const scenarioLabels = [
  {
    code: 'pull',
    name: 'Credit',
    icon: 'pi pi-arrow-right',
    color: 'black',
  },
  {
    code: 'push',
    name: 'Debit',
    icon: 'pi pi-arrow-left',
    color: 'green',
  },
  {
    code: 'cancel',
    name: 'Cancel',
    icon: 'pi pi-times',
    color: 'red',
  },
  {
    code: 'adjustmentReverse',
    name: 'Adjustment',
    icon: 'pi pi-arrows-h',
    color: 'blue',
  },
  {
    code: 'refund',
    name: 'Refund',
    icon: 'pi pi-undo',
    color: 'orange',
  },
  {
    code: 'reverse',
    name: 'Reversal',
    icon: 'pi pi-sync',
    color: 'purple',
  },
];
