import { useContext, useEffect } from 'react';

import { MainContext } from '../../../contexts/mainContext';
import { ActionTypes } from '../../../enums/enums';

import StepOne from '../StepOne/StepOne';
import StepTwo from '../StepTwo/StepTwo';
import MainCard from '../../../features/MainCard';

const ForgotPassword = () => {
  const { state, dispatch } = useContext(MainContext);
  const { step } = state;

  useEffect(() => {
    return () => {
      dispatch({ type: ActionTypes.SetStep, payload: 1 });
    };
  }, [dispatch]);

  return <MainCard>{step === 1 ? <StepOne /> : <StepTwo />}</MainCard>;
};

export default ForgotPassword;
