import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { FaCheck } from 'react-icons/fa6';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormHeader } from '@components/FormHeader/FormHeader';
import { Visa } from '@components/Visa/Visa';
import { useTranslation } from 'react-i18next';
import { useFormData } from 'contexts/FormDataContext';

import { saveInvoiceInfo } from '@services/save-invoice-info';
import { ListPages } from '@enums/enums';
import style from './DisbursementForm.module.scss';

interface FieldValidation {
  required: boolean;
  pattern?: string;
  patternMessage?: string;
}

type ErrorMessages = {
  [key: string]: string;
};

interface ValidationSchema {
  [key: string]: FieldValidation;
}

const validationSchema: ValidationSchema = {
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  streetAddress: {
    required: true,
  },
  country: {
    required: true,
  },
  city: {
    required: true,
  },
  zipCode: {
    required: true,
  },
  cardType: {
    required: true,
  },
  cardHolderName: {
    required: true,
  },
  cardNumber: {
    required: true,
    pattern: '^4[0-9]{15}?$',
    patternMessage: 'Invalid card number',
  },
  cardExp: {
    required: true,
  },
  agreed: {
    required: true,
  },
};

const DisbursementForm: React.FC = () => {
  const { t } = useTranslation();
  const toast = useRef<Toast>(null);

  const [formData, setFormData] = useFormData();
  const [errors, setErrors] = useState<ErrorMessages>({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('userToken') || '';

  const showToast = (
    severity: 'success' | 'info' | 'warn' | 'error',
    summary: string,
    detail: string
  ) => {
    if (toast.current) {
      toast.current.show({ severity, summary, detail });
    }
  };

  useEffect(() => {
    if (!token) {
      navigate(ListPages.EmailValidationError);
    }
  }, [token, navigate]);

  const handleChange = (name: keyof typeof formData, value: any) => {
    setFormData((prevFormData: FormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCardExpDateChange = (value: string) => {
    let cleanValue = value.replace(/[^0-9/]/g, '');

    if (cleanValue?.length < formData?.cardExp?.length) {
      handleChange('cardExp', cleanValue);
      return;
    }

    let [month, year = ''] = cleanValue.split('/');

    if (month.length > 2) {
      month = month.slice(0, 2);
    }

    const monthNum = parseInt(month, 10);
    if (
      month.length === 2 &&
      (Number.isNaN(monthNum) || monthNum < 1 || monthNum > 12)
    ) {
      month = month.slice(0, 1);
    }

    const currentYearLastTwoDigits = new Date().getFullYear() % 100;
    if (year.length > 2) {
      year = year.slice(0, 2);
    }

    const yearNum = parseInt(year, 10);
    if (year.length === 2) {
      if (yearNum < currentYearLastTwoDigits) {
        year = '';
      }
      const currentMonth = new Date().getMonth() + 1;
      if (yearNum === currentYearLastTwoDigits) {
        if (monthNum < currentMonth) {
          month = '';
          year = '';
        }
      }
    }

    if (month.length === 2 && !year) {
      cleanValue = `${month}/`;
    } else {
      cleanValue = `${month}${year ? `/${year}` : ''}`;
    }

    handleChange('cardExp', cleanValue);
  };

  const validateForm = () => {
    const newErrors: ErrorMessages = {};

    const fields = Object.keys(validationSchema);
    fields.forEach((field) => {
      const fieldSchema = validationSchema[field];
      let fieldValue = formData[field];

      if (typeof fieldValue !== 'boolean') {
        fieldValue = fieldValue?.toString()?.trim();
      }

      if (fieldSchema.required && !fieldValue) {
        newErrors[field] = 'Complete this field to continue';
      } else if (fieldSchema.pattern && fieldValue) {
        const regex = new RegExp(fieldSchema.pattern);
        if (!regex.test(fieldValue)) {
          newErrors[field] = fieldSchema.patternMessage ?? 'Invalid input';
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const hasErrors = validateForm();
    if (!hasErrors) return;
    setLoading(true);

    const body: any = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'cardExp' && typeof value === 'string') {
        const parts = value.split('/');
        if (parts.length === 2) {
          const [month, year] = parts;
          body[key] = `20${year}-${month}`;
        } else {
          body[key] = value;
        }
      } else {
        body[key] =
          typeof value === 'boolean' ? value : value?.toString()?.trim();
      }
    });

    try {
      const response = await saveInvoiceInfo(body, token);
      setLoading(false);

      if (response.status === 200) {
        navigate(ListPages.DisbursementFormSuccess);
      } else if (response.status === 404 || response.status === 400) {
        navigate(ListPages.EmailValidationError);
      } else {
        showToast(
          'error',
          'Error',
          response.errorMessage || 'An error occurred'
        );
      }
    } catch (error) {
      setLoading(false);
      showToast('error', 'Error', 'An unexpected error occurred');
    }
  };

  return (
    <div className={style.containerPage}>
      <Toast ref={toast} />

      <form className={style.containerForm} onSubmit={handleSubmit}>
        <div className={`p-fluid ${style.containerData}`}>
          <div>
            <FormHeader />
          </div>

          <div className={style.containerMain}>
            <div>
              <h1 className={style.titleForm}>
                {' '}
                {t('disbursementForm.title')}
              </h1>
              <h3 className={style.subtitle}>
                {t('disbursementForm.subTitle')}
              </h3>
            </div>
            <div>
              <h2 className={style.personalInf}>
                {' '}
                {t('disbursementForm.personalInformation')}
              </h2>
              <h3 className={style.notes}>{t('disbursementForm.note')}</h3>
            </div>

            <div className={style.containerPersonalInformation}>
              <div className={`p-field ${style.inputsForm}`}>
                <label className={style.labelForm} htmlFor="firstName">
                  {t('disbursementForm.firstName')}
                </label>
                <InputText
                  placeholder={t('disbursementForm.firstName') as string}
                  className={`${style.formInput} ${
                    errors?.['firstName'] ? style.inputError : ''
                  }`}
                  id="firstName"
                  value={formData.firstName}
                  onChange={(e) => handleChange('firstName', e.target.value)}
                />
                {!!errors?.['firstName'] && (
                  <small className="p-error">{errors?.['firstName']}</small>
                )}
              </div>
              <div className={`p-field ${style.inputsForm} `}>
                <label className={style.labelForm} htmlFor="lastName">
                  {t('disbursementForm.lastName')}
                </label>
                <InputText
                  placeholder={t('disbursementForm.lastName') as string}
                  className={`${style.formInput} ${
                    errors?.['lastName'] ? style.inputError : ''
                  }`}
                  id="lastName"
                  value={formData.lastName}
                  onChange={(e) => handleChange('lastName', e.target.value)}
                />
                {!!errors?.['lastName'] && (
                  <small className="p-error">{errors?.['lastName']}</small>
                )}
              </div>
            </div>

            <div className={style.containerBillingA}>
              <div>
                <h2 className={style.personalInf}>
                  {t('disbursementForm.billingAddress')}
                </h2>
              </div>

              <div className={`p-field ${style.containerStreetAddress}`}>
                <label className={style.labelForm} htmlFor="streetAddress">
                  {t('disbursementForm.streetAddress')}
                </label>
                <InputText
                  id="streetAddress"
                  placeholder={t('disbursementForm.streetAddress') as string}
                  className={`${style.formInput} ${
                    errors?.['streetAddress'] ? style.inputError : ''
                  }`}
                  value={formData.streetAddress}
                  onChange={(e) =>
                    handleChange('streetAddress', e.target.value)
                  }
                />
                {!!errors?.['streetAddress'] && (
                  <small className="p-error">{errors?.['streetAddress']}</small>
                )}
              </div>
              <div className={`p-field ${style.containerCountry} `}>
                <div className={`p-field ${style.inputConuntry}`}>
                  <label className={style.labelForm} htmlFor="country">
                    {t('disbursementForm.country')}
                  </label>
                  <Dropdown
                    id="country"
                    className={`${style.formInputCard} ${
                      errors?.['country'] ? style.inputError : ''
                    }`}
                    value={formData.country}
                    onChange={(e) => handleChange('country', e.value)}
                    options={[{ label: 'Puerto Rico', value: 'Puerto Rico' }]}
                    placeholder={t('disbursementForm.country') as string}
                  />
                  {!!errors?.['country'] && (
                    <small className="p-error">{errors?.['country']}</small>
                  )}
                </div>

                <div className={`p-field ${style.inputCity} `}>
                  <label className={style.labelForm} htmlFor="city">
                    {t('disbursementForm.city')}
                  </label>
                  <InputText
                    placeholder={t('disbursementForm.city') as string}
                    id="city"
                    className={`${style.formInput} ${
                      errors?.['city'] ? style.inputError : ''
                    }`}
                    value={formData.city}
                    onChange={(e) => handleChange('city', e.target.value)}
                  />
                  {!!errors?.['city'] && (
                    <small className="p-error">{errors?.['city']}</small>
                  )}
                </div>

                <div className={`p-field ${style.inputZipCode} `}>
                  <label className={style.labelForm} htmlFor="zipCode">
                    {t('disbursementForm.zipCode')}
                  </label>
                  <InputText
                    id="zipCode"
                    placeholder={t('disbursementForm.zipCode') as string}
                    className={`${style.formInput} ${
                      errors?.['zipCode'] ? style.inputError : ''
                    }`}
                    value={formData.zipCode}
                    onChange={(e) => handleChange('zipCode', e.target.value)}
                  />
                  {!!errors?.['zipCode'] && (
                    <small className="p-error">{errors?.['zipCode']}</small>
                  )}
                </div>
              </div>
            </div>
            <div className={style.billingInformation}>
              <div>
                <h2>{t('disbursementForm.billingInformation')}</h2>
              </div>
              <div className={style.containerVisa}>
                <div className={`p-field ${style.inputCard}`}>
                  <label className={style.labelForm} htmlFor="cardType">
                    {t('disbursementForm.cardType')}
                  </label>
                  <Dropdown
                    id="cardType"
                    className={`${style.formInputCard} ${
                      errors?.['cardType'] ? style.inputError : ''
                    }`}
                    value={formData.cardType}
                    onChange={(e) => handleChange('cardType', e.value)}
                    options={[
                      { label: 'Visa Debit', value: '20' },
                      { label: 'Visa Credit', value: '30' },
                    ]}
                    placeholder={t('disbursementForm.cardType') as string}
                  />
                  {!!errors?.['cardType'] && (
                    <small className="p-error">{errors?.['cardType']}</small>
                  )}
                </div>

                <div className={style.inputTypeAsset}>
                  <Visa />
                </div>
              </div>

              <div className="p-field">
                <label className={style.labelForm} htmlFor="cardHolderName">
                  {t('disbursementForm.cardHolderName')}
                </label>
                <InputText
                  id="cardHolderName"
                  placeholder={t('disbursementForm.cardHolderName') as string}
                  className={`${style.formInput} ${
                    errors?.['cardHolderName'] ? style.inputError : ''
                  }`}
                  value={formData.cardHolderName}
                  onChange={(e) =>
                    handleChange('cardHolderName', e.target.value)
                  }
                />
                {!!errors?.['cardHolderName'] && (
                  <small className="p-error">
                    {errors?.['cardHolderName']}
                  </small>
                )}
              </div>
              <div className={style.cardInputs}>
                <div className={`p-field ${style.cardNumerContainer}`}>
                  <label className={style.labelForm} htmlFor="cardNumber">
                    {t('disbursementForm.cardNumber')}
                  </label>
                  <InputText
                    id="cardNumber"
                    placeholder={t('disbursementForm.cardNumber') as string}
                    className={`${style.formInput} ${
                      errors?.['cardNumber'] ? style.inputError : ''
                    }`}
                    value={formData.cardNumber}
                    onChange={(e) => handleChange('cardNumber', e.target.value)}
                  />
                  {!!errors?.['cardNumber'] && (
                    <small className="p-error">{errors?.['cardNumber']}</small>
                  )}
                </div>

                <div className="p-field">
                  <label className={style.labelForm} htmlFor="cardExp">
                    {t('disbursementForm.cardExp')}
                  </label>
                  <InputText
                    id="cardExp"
                    className={`${style.formInput} ${
                      errors?.['cardExp'] ? style.inputError : ''
                    }`}
                    value={formData.cardExp || ''}
                    onChange={(e) => handleCardExpDateChange(e.target.value)}
                    placeholder="MM/YY"
                    maxLength={5}
                  />
                  {!!errors?.['cardExp'] && (
                    <small className="p-error">{errors?.['cardExp']}</small>
                  )}
                </div>
              </div>
            </div>

            <div className={`p-field-checkbox ${style.containerCheckBox}`}>
              <Checkbox
                className={`${style.checkbox} ${
                  errors?.['agreed'] ? style.inputError : ''
                }`}
                inputId="agreed"
                checked={formData.agreed}
                onChange={(e) => handleChange('agreed', e.checked)}
              />
              <label className={style.labelLink} htmlFor="agreed">
                {t('disbursementForm.agreed')}{' '}
                <Link
                  to="/terms-and-conditions"
                  className={style.linkContainer}
                >
                  Zenus terms and conditions
                </Link>
              </label>
            </div>

            <div className={style.containerButtonsDis}>
              <Button
                type="submit"
                label="Submit Information"
                className={style.buttonContinue}
                icon={<FaCheck size={16} className={style.rightIcon} />}
              />
            </div>
            {loading && (
              <div className={style.spinnerContainer}>
                <ProgressSpinner />
              </div>
            )}
            <div className="p-field">
              <h5 className={style.containerForSecurity}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </h5>
            </div>
            <div className={style.visaLogo}>
              <Visa />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DisbursementForm;
