import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useMsal } from '@azure/msal-react';
import { Message } from 'primereact/message';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { sendUsernameAndPassword } from '@services/sign-in';
import { ActionTypes, ListPages } from '@enums/enums';
import { MainContext } from '../../contexts/mainContext';
import MainCard from '../../features/MainCard';
import { msalInstance as instance } from '../../msalconfig';

import '../../styles/main.scss';

const b2cLoginURL: string =
  process.env['REACT_APP_B2C_LOGIN_URL']?.toString() ?? '';
const b2cForgotPasswURL: string =
  process.env['REACT_APP_B2C_FORGOT_PASSW_URL']?.toString() ?? '';
const authTokenName = 'authToken';

const forgotRedirect: string =
  process.env['REACT_APP_AUTHORITY_FORGOT']?.toString() ?? '';

const Login = () => {
  const { accounts, inProgress } = useMsal();

  const location = useLocation();
  const queryParamsErrors = new URLSearchParams(location.search).get('error');
  const [values, setValues] = useState({ username: '', password: '' });
  const [error, setError] = useState({
    username: false,
    password: false,
    alert: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { passwordChanged },
    dispatch,
  } = useContext(MainContext);

  useEffect(() => {
    const account = accounts[0];
    const token = account?.idToken;
    if (!token) return;
    sessionStorage.setItem(authTokenName, token);
    navigate(ListPages.DashboardReports, { replace: true });
  }, [accounts, navigate]);

  useEffect(() => {
    if (passwordChanged) {
      setShowMessage(true);
    }
    const authToken = sessionStorage.getItem(authTokenName);
    if (authToken) {
      navigate(ListPages.DashboardReports, { replace: true });
    }
    return () => {
      dispatch({ type: ActionTypes.SetPasswordChanged, payload: false });
    };
  }, [dispatch, showMessage, passwordChanged, location, navigate]);

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    let errMessage;
    if (!values.password || !values.username) {
      setError({
        username: !values.username,
        password: !values.password,
        alert: false,
      });
      errMessage = t('login.errors.required');
      return setErrorMessage(errMessage);
    }
    setError({ username: false, password: false, alert: false });
    setErrorMessage('');

    try {
      const atoken = await sendUsernameAndPassword(
        values.username,
        values.password
      );
      dispatch({ type: ActionTypes.SetAToken, payload: atoken });
      dispatch({ type: ActionTypes.SetForgotPasswordFlow, payload: false });
      navigate(ListPages.Home, { replace: true });
      return undefined;
    } catch (e) {
      errMessage = t('login.errors.invalidCredentials');
      setErrorMessage(errMessage);
      setError({
        username: false,
        password: false,
        alert: true,
      });
      return undefined;
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name } = event.currentTarget;
    const { value } = event.currentTarget;
    setValues({ ...values, [name]: value });
  };

  if (inProgress !== 'none' || accounts[0]) return null;

  return (
    <MainCard>
      {queryParamsErrors ? (
        <div>
          <p className="text-center text-danger">
            {t(`login.errors.${queryParamsErrors}`)}
          </p>
        </div>
      ) : null}
      {showMessage ? (
        <div className="bg-green-100 border-round display flex flex-row p-3">
          <FontAwesomeIcon icon={faCheckCircle} size="lg" />
          <p className="m-0 text-left ml-3 text-sm">
            <span className="font-bold">
              {' '}
              {t('login.passwordChangedMessage.part1')}
            </span>{' '}
            {t('login.passwordChangedMessage.part2')}
          </p>
        </div>
      ) : null}

      {error.alert ? <Message text={errorMessage} severity="error" /> : null}

      <form onSubmit={handleSubmit}>
        <Button
          type="button"
          className=" customButton mt-4 w-full flex justify-content-center p-button-raised font-bold text-lg"
          onClick={() => {
            instance.loginRedirect({
              prompt: 'login',
              scopes: ['openid'],
              nonce: 'defaultNonce',
            });
          }}
        >
          {t('login.button')}
        </Button>
        <Button
          type="button"
          className="text-sm font-bold cursor-pointer text-black text-700 p-button-text p-button-plain"
          onClick={() => {
            instance.loginRedirect({
              scopes: ['openid'],
              nonce: 'defaultNonce',
              authority: forgotRedirect,
            });
          }}
        >
          {t('login.forgotPassword')}
        </Button>
      </form>
    </MainCard>
  );
};

export default Login;
