import React, { useContext, useEffect, useState } from 'react';
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';

import { Message } from 'primereact/message';
import { SectionCard } from '@components/SectionCard/SectionCard';
import { sendPasswords } from '@services/change-password';
import { ActionTypes, ListPages } from '@enums/enums';
import { MainContext } from '../../contexts/mainContext';

const ChangePassword = () => {
  const [visiblePassword, setVisiblePassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [visibleCurrentPassword, setVisibleCurrentPassword] = useState(false);

  const [error, setError] = useState({
    password: false,
    confirmPassword: false,
  });

  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [successChangePassword, setSuccessChangePassword] = useState(false);
  const [failChangePasswordMessage, setFailChangePasswordMessage] =
    useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [values, setValues] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: '',
  });

  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] =
    useState('');

  const navigate = useNavigate();
  const { dispatch, state } = useContext(MainContext);
  const { forgotPasswordFlow } = state;
  const { t } = useTranslation();

  useEffect(() => {
    if (state.successPasswordChanged) {
      navigate(ListPages.Login, { replace: true });
    }

    return () => {
      dispatch({ type: ActionTypes.SetChangePass, payload: false });
    };
  }, [state.successPasswordChanged, dispatch, navigate]);

  const validatePassword = (password: string) => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,50}$/;
    return regex.test(`${password}`);
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValidOldPassword =
      validatePassword(values.currentPassword) || forgotPasswordFlow;
    const isValidNewPassword = validatePassword(values.password);

    if (!values.currentPassword && !forgotPasswordFlow) {
      setCurrentPasswordError(!values.currentPassword);
      return setCurrentPasswordErrorMessage('Este campo es obligatorio');
    }

    if (!isValidOldPassword && !forgotPasswordFlow) {
      setCurrentPasswordError(true);
      return setCurrentPasswordErrorMessage(
        'Formato inválido, debe contener al menos 8 caracteres, 1 mayúscula, 1 minúscula, un símbolo y un número'
      );
    }

    let currentPasswordErrMessage;
    if (!values.currentPassword && !forgotPasswordFlow) {
      setCurrentPasswordError(!values.currentPassword);
      currentPasswordErrMessage = t('login.errors.required');
      return setCurrentPasswordErrorMessage(currentPasswordErrMessage);
    }
    setCurrentPasswordError(false);
    setCurrentPasswordErrorMessage('');

    if (!values.password || !values.confirmPassword) {
      setError({
        password: !values.password,
        confirmPassword: !values.confirmPassword,
      });
      return setErrorMessage('Este campo es obligatorio');
    }

    if (!isValidNewPassword) {
      setError({
        confirmPassword: false,
        password: true,
      });
      return setErrorMessage(
        'Formato inválido, debe contener al menos 8 caracteres, 1 mayúscula, 1 minúscula, un símbolo y un número'
      );
    }
    if (values.password !== values.confirmPassword) {
      setError({ ...error, confirmPassword: true });
      return setErrorMessage('Las contraseñas no coinciden');
    }

    let errMessage;
    if (!values.password || !values.confirmPassword) {
      setError({
        password: !values.password,
        confirmPassword: !values.confirmPassword,
      });
      errMessage = t('login.errors.required');
      return setErrorMessage(errMessage);
    }
    setError({
      password: false,
      confirmPassword: false,
    });
    setErrorMessage('');

    try {
      const { atoken } = state;
      await sendPasswords(
        values.currentPassword,
        values.password,
        values.confirmPassword,
        atoken
      );
      setSuccessChangePassword(true);
      setFailChangePasswordMessage('');
      navigate(ListPages.Home);
      return undefined;
    } catch (er: any) {
      setSuccessChangePassword(false);
      setFailChangePasswordMessage(er);
      setError({
        password: false,
        confirmPassword: false,
      });
      setCurrentPasswordError(false);
      dispatch({ type: ActionTypes.SetForgotPasswordFlow, payload: false });
      dispatch({ type: ActionTypes.SetPasswordChanged, payload: true });
      return dispatch({ type: ActionTypes.SetChangePass, payload: true });
    }
  };

  return (
    <SectionCard>
      <form onSubmit={handleSubmit}>
        <p className="flex justify-content-start text-3xl font-bold my-3 text-black-alpha-90 title">
          {t('changePassword.section')}
        </p>
        <p className="text-2xl">{t('changePassword.title2')}</p>

        {!successChangePassword && !failChangePasswordMessage ? (
          <div className="bg-blue-100 border-round display flex flex-row p-3">
            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
            <p className="m-0 text-left ml-3 text-sm">
              {t('changePassword.passwordInfo')}
            </p>
          </div>
        ) : null}

        {successChangePassword && !failChangePasswordMessage ? (
          <div className="bg-green-100 border-round display flex flex-row p-3">
            <FontAwesomeIcon icon={faCheckCircle} size="lg" />
            <p className="m-0 text-left ml-3 text-sm">
              <span className="font-bold">
                {' '}
                {t('login.passwordChangedMessage.part1')}
              </span>{' '}
              {t('login.passwordChangedMessage.part2')}
            </p>
          </div>
        ) : null}

        {failChangePasswordMessage && !successChangePassword ? (
          <Message text={`${failChangePasswordMessage}`} severity="error" />
        ) : null}

        <div className="mt-3">
          {!forgotPasswordFlow ? (
            <div>
              <p className="flex align-self-start text-xs mb-1 text-600">
                {t('changePassword.currentPassword')}
              </p>
              <div className="flex flex-column m-0 p-0">
                <div className="p-inputgroup mt-0">
                  <InputText
                    name="currentPassword"
                    onChange={(e) => handleChange(e)}
                    type={visibleCurrentPassword ? 'text' : 'password'}
                    placeholder={t('changePassword.currentPassword') as string}
                    className={` w-full border-right-none ${
                      currentPasswordError ? 'p-invalid' : 'p-inputtext'
                    }`}
                  />
                  <Button
                    type="button"
                    name="currentPassword"
                    icon={
                      !visibleCurrentPassword ? 'pi pi-eye' : 'pi pi-eye-slash'
                    }
                    onClick={() =>
                      setVisibleCurrentPassword(!visibleCurrentPassword)
                    }
                    className={`bg-white text-600   ${
                      error.password ? 'eyeButton-invalid' : 'eyeButton'
                    } border-left-none`}
                  />
                </div>
                {currentPasswordError ? (
                  <p className="text-xs m-0 text-red-600 ml-2 ">
                    {currentPasswordErrorMessage}
                  </p>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="flex flex-column  m-0 p-0">
            <p className="flex align-self-start text-xs mb-1 text-600">
              {t('changePassword.newPassword')}
            </p>
            <div className="p-inputgroup mt-0">
              <InputText
                name="password"
                onChange={(e) => handleChange(e)}
                type={
                  visiblePassword.confirmPassword || visiblePassword.password
                    ? 'text'
                    : 'password'
                }
                placeholder={t('changePassword.newPassword') as string}
                className={` w-full border-right-none ${
                  error.password ? 'p-invalid' : 'p-inputtext'
                }`}
              />
              <Button
                type="button"
                name="password"
                icon={
                  !visiblePassword.confirmPassword
                    ? 'pi pi-eye'
                    : 'pi pi-eye-slash'
                }
                onClick={() =>
                  setVisiblePassword({
                    ...visiblePassword,
                    confirmPassword: !visiblePassword.confirmPassword,
                  })
                }
                className={`bg-white text-600   ${
                  error.confirmPassword ? 'eyeButton-invalid' : 'eyeButton'
                } border-left-none`}
              />
            </div>
            {error.password ? (
              <p className="text-xs m-0 text-red-600 ml-2 ">{errorMessage}</p>
            ) : null}
          </div>

          <p className="flex align-self-start text-xs mb-1 text-600">
            {t('changePassword.repeatPassword')}
          </p>
          <div className="flex flex-column">
            <div className="p-inputgroup">
              <InputText
                onChange={(e) => handleChange(e)}
                name="confirmPassword"
                type={visiblePassword.confirmPassword ? 'text' : 'password'}
                placeholder={t('changePassword.repeatPassword') as string}
                className={`w-full border-right-none ${
                  error.confirmPassword ? 'p-invalid' : 'p-inputtext'
                }`}
              />
              <Button
                type="button"
                icon={
                  !visiblePassword.confirmPassword
                    ? 'pi pi-eye'
                    : 'pi pi-eye-slash'
                }
                onClick={() =>
                  setVisiblePassword({
                    ...visiblePassword,
                    confirmPassword: !visiblePassword.confirmPassword,
                  })
                }
                className={`bg-white text-600  border-black-alpha-20 border-left-none ${
                  error.confirmPassword ? 'eyeButton-invalid' : 'eyeButton'
                }`}
              />
            </div>
            {error.confirmPassword ? (
              <p className="m-0 ml-2 text-xs text-red-600">{errorMessage}</p>
            ) : null}
          </div>
        </div>

        <Button
          className="aling-self-center w-full justify-content-center mt-4 font-bold p-button-raised p-button-success customMainButton"
          type="submit"
        >
          {t('changePassword.changePasswordButton')}
        </Button>
      </form>
    </SectionCard>
  );
};

export default ChangePassword;
