import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles.footer}>
      <a href="https://zenus.com/" className={styles.link}>
        {t('footer.about')}
      </a>
      <a href="https://zenus.com/" className={styles.link}>
        {t('footer.privacy')}
      </a>
      <a href="https://zenus.com/" className={styles.link}>
        {t('footer.help')}
      </a>
      <a href="https://zenus.com/" className={styles.link}>
        {t('footer.termin')}
      </a>
      <span className={styles.rights}>
        © 2023 Zenus Bank Inc. All rights reserved.
      </span>
    </footer>
  );
};

export default Footer;
