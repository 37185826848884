import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayPanel } from 'primereact/overlaypanel';
import styles from './UserMenu.module.scss';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';

type UserMenuPropsType = {
  userName: string;
  userPic: string;
};

export const UserMenu = (props: UserMenuPropsType) => {
  const { userName, userPic } = props;
  const { t } = useTranslation();
  const settings = t('Settings');
  const keyboar = 'Enter';

  const op = useRef<OverlayPanel>(null);

  const items = [
    {
      id: '01',
      label: settings,
      icon: 'pi pi-fw pi-cog',
      command: () => {},
    },
  ];

  return (
    <div className={styles.userMenuContainer}>
      <p className={styles.userName}>{userName}</p>
      {userPic ? (
        <img src={userPic} alt="user profile" />
      ) : (
        <div className={styles.buttonContainer}>
          <div
            className={`${styles.buttonCustom} p-button-secondary p-button-outlined`}
          >
            <FontAwesomeIcon
              icon={faUserCircle as IconProp}
              size="lg"
              className={styles.userLogo}
            />
          </div>
          <OverlayPanel ref={op} dismissable>
            {items.map((item) => (
              <div
                key={item.id}
                onClick={item.command}
                onKeyDown={(e) => {
                  if (e.key === keyboar) item.command();
                }}
                role="button"
                tabIndex={0}
                className={styles.menuItem}
              >
                <i className={item.icon} />
                <span>{item.label}</span>
              </div>
            ))}
          </OverlayPanel>
        </div>
      )}
    </div>
  );
};
