import { isAxiosError } from 'axios';
import { axiosFuex } from '@httpClients';
import { QuickActionsType } from 'types/ActionsType';

export const quickActions = async (
  body: QuickActionsType
): Promise<{ data: any; status: number }> => {
  try {
    const authToken = sessionStorage.getItem('authToken');
    const authHeader = `Bearer ${authToken}`;
    const raw = JSON.stringify(body);
    const path = '/recipients/quick-actions';

    const response = await axiosFuex.patch(path, raw, {
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
      },
    });

    if (response.data.errors) {
      throw new Error(response.data.errors);
    }

    return { data: response.data.data, status: response.status };
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(`Error ${error.response?.status}: ${error.message}`);
    } else {
      throw error;
    }
  }
};
