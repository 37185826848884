import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { MainContext } from '../contexts/mainContext';

const useNavigationGuards = () => {
  const { state } = useContext(MainContext);
  if (!state.atoken) {
    return <Navigate to="/landing" replace />;
  }
  return null;
};

export default useNavigationGuards;
