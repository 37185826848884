import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { sendUsername } from '@services/forgot-password';
import { ActionTypes } from '@enums/enums';
import { MainContext } from '../../../contexts/mainContext';

import styles from './StepOne.module.scss';

const StepOne = () => {
  const { dispatch, state } = useContext(MainContext);
  const [username, setUsername] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (state.errorStep1) {
      setError(true);
      if (state.errorMessage) {
        setErrorMessage(state.errorMessage);
      }
    }
    return () => {
      setError(false);
      setErrorMessage('');
    };
  }, [state.errorStep1, state.errorMessage, dispatch]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!username) {
        setError(true);
        return setErrorMessage('Este campo es obligatorio');
      }
      setError(false);
      setErrorMessage('');
      await sendUsername(username);
      return dispatch({
        type: ActionTypes.SendUser,
        payload: { state: 'success', username },
      });
    } catch (err) {
      return dispatch({
        type: ActionTypes.SendUser,
        payload: { state: 'error', username: '' },
      });
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value);
  };

  return (
    <>
      <p className="flex justify-content-start text-3xl font-bold my-3 text-black-alpha-90 title">
        {t('forgotPassword.stepOne.title')}
      </p>
      <p className="text-sm">{t('forgotPassword.stepOne.subtitle')}</p>
      <p className="flex align-self-start text-xs mb-1 text-600">
        {t('forgotPassword.stepOne.username')}
      </p>
      <form onSubmit={handleSubmit}>
        <InputText
          className={`w-full  ${error ? 'p-invalid' : 'p-textinput'}`}
          placeholder="Username"
          onChange={(e) => handleChange(e)}
        />
        {error ? (
          <p key="message" className="text-xs m-0 ml-2 text-red-600">
            {errorMessage}
          </p>
        ) : null}
        <div className="flex flex-column justify-content-around mt-3 gap-3">
          <Button
            type="submit"
            className="mt-4 flex justify-content-center p-button-raised p-button-success font-bold text-lg customMainButton"
          >
            {t('forgotPassword.stepOne.sendButton')}
          </Button>
          <Button
            type="button"
            onClick={() => navigate('/', { replace: true })}
            className={`customSecondaryButton bg-white text-900 border-none  align-self-center text-center text-sm ${styles.goBackBtn}`}
            icon={<i className="pi pi-chevron-left text-sm " />}
            label={t('forgotPassword.stepOne.backButton') as string}
          />
        </div>
      </form>
    </>
  );
};

export default StepOne;
