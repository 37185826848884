import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { FaTriangleExclamation, FaPlus, FaCircleXmark } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import Header from '@components/Header/Header';
import { ListPages } from '@enums/enums';
import SideBar from '@components/SideBar/SideBar';
import styles from '../DashboardReports/DashboardReports.module.scss';
import style from '../Disbursement/Disbursement.module.scss';
import styleNew from '../NewDisbursementSingle/NewDisbursementSingle.module.scss';
import styleSuccess from '../DisbursementSuccess/DisbursementSuccess.module.scss';
import styleError from './DisbursementError.module.scss';

const DisbursementError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const errorColor = '#e60101';

  const handleGoBack = () => {
    navigate(ListPages.Disbursement, { replace: true });
  };
  return (
    <div className={styles.pageContainer}>
      <Header />

      <div className={styles.mainSection}>
        <SideBar />
        <div className={styles.mainSectionWorkArea}>
          <div>
            <div className="disbursement-layout">
              <div className={styles.tableHeader}>
                <h1 className={styles.headerText}>
                  {t('disbursementError.title')}
                </h1>
              </div>

              <div className={styleSuccess.disbursementSuccessWorkingArea}>
                <div className={styleSuccess.containerDataSuccess}>
                  <FaCircleXmark size={41} color={errorColor} />
                  <h3 className={styleNew.subTitle}>
                    {t('disbursementError.subTitle')}
                  </h3>
                </div>
                <div className={styleNew.divider} />
                <div className={styleError.containerErrorT}>
                  <div>
                    <FaTriangleExclamation size={24} color={errorColor} />
                  </div>
                  <div className={styleError.containerTransactionError}>
                    <label htmlFor="id" className={styleError.labelErrorT}>
                      {t('disbursementError.labelInformationError')}
                    </label>
                    <label htmlFor="id" className={styleError.labelErrorD}>
                      {t('disbursementError.labelSuggestion')}
                    </label>
                  </div>
                </div>
                <div className={styleNew.containerData}>
                  <div className={styleNew.containerCard}>
                    <label htmlFor="email" className={styleNew.labelContainer}>
                      {t('disbursement.email')}:
                    </label>
                    <InputText
                      className={styleNew.inputDescription}
                      id="email"
                      value="test@test.com"
                      readOnly
                    />
                  </div>
                  <div className="p-field">
                    <label
                      className={styleNew.labelContainer}
                      htmlFor="totalAmount"
                    >
                      {t('newDisbursement.totalAmount')}
                    </label>
                    <InputText
                      className={styleNew.inputDescription}
                      id="totalAmount"
                      value="$12345.00"
                      readOnly
                    />
                  </div>
                </div>
                <div className={styleNew.divider} />

                <div className={style.containerButtonsDis}>
                  <Link to={ListPages.Disbursement}>
                    <Button
                      className={styleSuccess.buttonAnotherD}
                      icon={<FaPlus size={20} />}
                    >
                      {t('disbursement.buttonMakeAnother')}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisbursementError;
