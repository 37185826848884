import loading from '../../assets/Loader.svg';
import styles from './Loading.module.scss';

const LoadingSvg = () => {
  const loadingPath = loading as unknown as string;
  return (
    <img
      alt="zenus bank logo"
      src={loadingPath}
      className={styles.loadingStyle}
    />
  );
};

export default LoadingSvg;
