import NavBarItem from './NavBarItem';

const NavBar = () => {
  return (
    <ul>
      <NavBarItem name="Edit Profile" />
      <NavBarItem name="Password & Security" selected />
    </ul>
  );
};

export default NavBar;
