import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { FaUnlockKeyhole } from 'react-icons/fa6';
import { ListPages } from '@enums/enums';
import Header from '@components/Header/Header';
import LoadingSvg from '@components/Loading/LoadingSvg';
import { Link, useNavigate } from 'react-router-dom';
import SideBar from '@components/SideBar/SideBar';
import { createDisbursement } from '@services/create-disbursement';
import { useDisbursement } from 'contexts/DisbursementContext';
import { DisbursementType } from 'types/DisbursementType';
import styles from '../DashboardReports/DashboardReports.module.scss';
import style from '../Disbursement/Disbursement.module.scss';
import styleNew from '../NewDisbursementSingle/NewDisbursementSingle.module.scss';
import disbursementStyle from './DisbursementProcessed.module.scss';

import Status from './Status';

const DisbursementProcessed = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { email, amount, currency, concept, setDisbursementNumber } =
    useDisbursement();

  const disbursement = useMemo(() => {
    return {
      email,
      amount: Number(amount),
      currency,
      concept,
    };
  }, [email, amount, currency, concept]);

  useEffect(() => {
    const sendData = async () => {
      try {
        const res = await createDisbursement(disbursement);
        if (res && res.data) {
          setDisbursementNumber(res.data);
        }
        navigate(ListPages.DisbursementSuccess);
      } catch (error) {
        navigate(ListPages.DisbursementError);
      }
    };
    sendData();
  }, [navigate, disbursement, setDisbursementNumber]);

  return (
    <div className={styles.pageContainer}>
      <Header />

      <div className={styles.mainSection}>
        <SideBar />
        <div className={styles.mainSectionWorkArea}>
          <div>
            <div className="disbursement-layout">
              <div className={styles.tableHeader}>
                <h1 className={styles.headerText}>
                  {t('newDisbursement.title')}
                </h1>
              </div>

              <div
                className={disbursementStyle.processedDisbursementWorkingArea}
              >
                <div className={disbursementStyle.containerLoadingInformation}>
                  <div>
                    <div
                      className={disbursementStyle.disbursementContainerData}
                    >
                      <h3 className={disbursementStyle.subTitle}>
                        {t('disbursementProcessed.subTitle')}
                      </h3>
                    </div>
                    <div>
                      <div className={disbursementStyle.containerDataProcessed}>
                        <div className={styleNew.containerCard}>
                          <label
                            htmlFor="cardNumber"
                            className={styleNew.labelContainer}
                          >
                            {t('newDisbursement.email')}
                          </label>
                          <InputText
                            className={disbursementStyle.inputDescriptionP}
                            id="email"
                            value={email}
                            readOnly
                          />
                        </div>
                        <div className="p-field">
                          <label
                            className={styleNew.labelContainer}
                            htmlFor="totalAmount"
                          >
                            {t('newDisbursement.totalAmount')}
                          </label>
                          <InputText
                            className={disbursementStyle.inputDescriptionP}
                            id="totalAmount"
                            value={amount}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <LoadingSvg />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisbursementProcessed;
