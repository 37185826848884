import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import App from './App';
import './index.css';

import './i18n';

import { msalInstance } from './msalconfig';

msalInstance.initialize();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </MsalProvider>
);
