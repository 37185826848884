import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useCallback, useRef, useState } from 'react';
import {
  FaMagnifyingGlass,
  FaArrowsRotate,
  FaArrowRotateLeft,
} from 'react-icons/fa6';
import { ColumnHeaders } from '@enums/enums';
import { InputText } from 'primereact/inputtext';
import styles from './FilterSection.module.scss';

export const FilterSection = (props: any) => {
  const { t } = useTranslation();
  const keyboardEvent = 'Enter';

  const {
    isLoading,
    searchButtonHandler,
    typeDropdownHandler,
    filterDropdownHandler,
    resetFiltersHandler,
    fetchDataAndSetCooldown,
    isRecipients,
  } = props;

  const cardNumberMaxLength = 19;
  const cardNumberMinLength = 4;
  const transactionIdMaxLength = 20;
  const transactionIdMinLength = 12;
  const defaultMaxLength = 50;
  const defaultMinLength = 0;

  const filterOptions = [
    { name: 'Select a Filter', code: '' },
    { name: 'Transaction Id', code: 'transactionId' },
    { name: 'Card Number', code: 'cardNumber' },
    { name: 'Amount', code: 'amount' },
  ];

  const transactionTypesOptions = [
    { name: 'Credit', code: 'pull' },
    { name: 'Debit', code: 'push' },
    { name: 'Reversal', code: 'reverse' },
    { name: 'Refund', code: 'refund' },
    { name: 'Cancel', code: 'cancel' },
    { name: 'Adjustment', code: 'adjustmentReverse' },
  ];

  const operationStatusOptions = [
    { name: 'View All', code: '' },
    { name: 'Approved', code: 'completed' },
    { name: 'Rejected', code: 'error' },
    { name: 'Capturing Data', code: 'capturingdata' },
    { name: 'In Process', code: 'inprogress' },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
  const [selectedTransactionType, setSelectedTransactionType] = useState(
    transactionTypesOptions[0]
  );

  const [selectedOperationStatus, setSelectedOperationStatus] = useState(
    operationStatusOptions[0]
  );

  const lastRefreshTime = () => {
    const inputTimestamp = new Date();
    const options: Intl.DateTimeFormatOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
      inputTimestamp
    );

    return formattedDate.replace(',', '');
  };

  const selectedTransactionTypeTemplate = (option: any, val: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{`${t('filterSection.transactionStatus')} ${option.name}`}</div>
        </div>
      );
    }
    return <span>{val?.placeholder}</span>;
  };

  const transactionTypeOptionTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <div>{`${t('filterSection.transactionStatus')} ${option.name}`}</div>
      </div>
    );
  };

  const onChangeTypeHandler = (selectedOption: any) => {
    setSelectedOperationStatus(selectedOption);
    setSelectedTransactionType(selectedOption);
  };

  type FormData = {
    search: string;
  };

  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const onChangeFilterHandler = (selectedOption: any) => {
    setSelectedFilter(selectedOption);
    reset({ search: '' });
  };

  const onClickSearchHandler = useCallback(
    (searchedText: string) => {
      const isFilterChange = true;
      const isPageSizeChange = false;
      const search = searchedText;
      const searchFilterType = selectedFilter;
      const transactionType = selectedTransactionType;
      const operationStatus = selectedOperationStatus;
      searchButtonHandler(
        isFilterChange,
        isPageSizeChange,
        search,
        searchFilterType,
        transactionType,
        operationStatus
      );
    },
    [
      selectedFilter,
      selectedTransactionType,
      searchButtonHandler,
      selectedOperationStatus,
    ]
  );

  const onSubmit = useCallback(
    (data: FormData) => {
      onClickSearchHandler(data.search);
    },
    [onClickSearchHandler]
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === keyboardEvent) {
      handleSubmit(onSubmit)();
    }
  };

  const validateSearchBar = (input: string): boolean => {
    if (selectedFilter.code !== '') {
      const nonEmptyregex = /^.+[^ ]$/i;
      const result = nonEmptyregex.test(input || '');
      return result;
    }
    const emptyRegex = /^$/i;
    const result = emptyRegex.test(input || '');
    return result;
  };

  const onClickResetFilterHandler = () => {
    reset({ search: '' });
    setSelectedFilter(filterOptions[0]);
    // setSelectedTransactionType(transactionTypesOptions[0]);
    setSelectedOperationStatus(operationStatusOptions[0]);
    // const search = null;
    // const searchFilterType = selectedFilter;
    // const transactionType = selectedTransactionType;
    // searchButtonHandler(search, searchFilterType, transactionType);
    const isFilterChange = true;
    resetFiltersHandler(isFilterChange);
  };

  const calculateMaxLength = (code: string) => {
    if (code === ColumnHeaders.transactionId) {
      return transactionIdMaxLength;
    }
    if (code === ColumnHeaders.cardNumber) {
      return cardNumberMaxLength;
    }
    return defaultMaxLength;
  };

  const calculateMinLength = (code: string) => {
    if (code === ColumnHeaders.transactionId) {
      return transactionIdMinLength;
    }
    if (code === ColumnHeaders.cardNumber) {
      return cardNumberMinLength;
    }
    return defaultMinLength;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.filterSectionContainer}>
        <div className={styles.filterSection}>
          <div className={styles.searchSection}>
            <Dropdown
              className={`${styles.filterComponent} w-full md:w-14rem`}
              value={selectedFilter}
              onChange={(e) => onChangeFilterHandler(e.value)}
              options={filterOptions}
              optionLabel="name"
              placeholder={filterOptions[0]?.name || 'None'}
            />
            <span>
              <Controller
                name="search"
                control={control}
                rules={{
                  required:
                    selectedFilter.code !== ''
                      ? 'Required when filter is selected'
                      : false,
                  validate: {
                    field: (value) => validateSearchBar(value),
                  },
                }}
                render={({ field }) => (
                  <InputText
                    disabled={selectedFilter.code === ''}
                    className={styles.searchInput}
                    placeholder={t('filterSection.search') as string}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    type="text"
                    maxLength={calculateMaxLength(selectedFilter.code)}
                    minLength={calculateMinLength(selectedFilter.code)}
                  />
                )}
              />
              {errors.search && (
                <p className={styles.errorMessage}>{errors.search.message}</p>
              )}
            </span>
          </div>
          <Dropdown
            className={`${styles.dropdownComponent} w-full md:w-14rem`}
            value={selectedOperationStatus}
            onChange={(e) => onChangeTypeHandler(e.value)}
            options={operationStatusOptions}
            optionLabel="name"
            placeholder={`${t('filterSection.transactionStatus')} ${
              operationStatusOptions[0]?.name
            }`}
            valueTemplate={selectedTransactionTypeTemplate}
            itemTemplate={transactionTypeOptionTemplate}
            onKeyDown={handleKeyDown}
          />
          <Button
            className={styles.searchButton}
            label={t('filterSection.apply') as string}
            icon="pi pi-search"
            disabled={isLoading}
            onClick={() => {
              trigger('search');
            }}
          />
          <div className={styles.containerReset}>
            <div>
              <Button
                className={styles.refreshButton}
                label="Reset Filters"
                onClick={onClickResetFilterHandler}
              />
            </div>
          </div>
        </div>
        <div className={styles.filterInfoSection}>
          <div className={styles.refreshSeccion}>
            <div className={styles.containerData}>
              <div className={styles.containerLastR}>
                <h4 className={styles.lastRefreshLabel}>Last refresh</h4>
              </div>
              <p className={styles.dateStyle}>{lastRefreshTime()}</p>
            </div>
            <div className={styles.reload}>
              <FaArrowsRotate
                color="white"
                size={16}
                onClick={fetchDataAndSetCooldown}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
