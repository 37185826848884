import { isAxiosError } from 'axios';
import { axiosAuth } from '@httpClients';

export const validateToken = async (token: string) => {
  const authTokenName = 'authToken';

  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
  } catch (error) {
    if (isAxiosError(error)) {
      sessionStorage.removeItem(authTokenName);
      throw new Error(error.response?.data?.errors[0].message);
    }
    throw error;
  }
};
