import { JwtAuthToken } from '@interfaces/interfaces';
import { decodeToken } from 'react-jwt';

export const validationToken = (): string | null => {
  const authTokenName = 'authToken';
  const authToken = sessionStorage.getItem(authTokenName) || '{}';
  const decodedToken: JwtAuthToken =
    decodeToken(authToken) || ({} as JwtAuthToken);

  if (decodedToken && decodedToken.city) {
    return decodedToken.city;
  }
  return null;
};
