import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FaArrowsRotate } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import styles from './EmailFilter.module.scss';
import stylesLR from '../FilterSection/FilterSection.module.scss';

interface EmailFilterProps {
  onFilter: (email: string) => void;
  fetchDataAndSetCooldown: (
    cursor: PaginationRecipientsCursorType,
    pageSize: number,
    isInitialLoad: boolean
  ) => void;
}

export const EmailFilter = ({
  onFilter,
  fetchDataAndSetCooldown,
}: EmailFilterProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const lastRefreshTime = () => {
    const inputTimestamp = new Date();
    const options: Intl.DateTimeFormatOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
      inputTimestamp
    );

    return formattedDate.replace(',', '');
  };

  const handleFilter = () => {
    onFilter(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleFilter();
    }
  };

  const handleReload = () => {
    setEmail('');
    onFilter('');
    const cursor = {
      filters: email ? { email } : {},
    };
    const pageSize = 15;
    fetchDataAndSetCooldown(cursor, pageSize, true);
  };

  return (
    <div className={styles.containerFilter}>
      <div className={styles.emailFilterContainer}>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={email}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={t('recipients.searchEmail') as string}
              className={styles.emailInput}
            />
          </span>
        </div>
        <div>
          <Button
            label={t('recipients.search') as string}
            icon="pi pi-search"
            onClick={handleFilter}
            className={styles.searchButton}
          />
        </div>
      </div>
      <div className={stylesLR.filterInfoSection}>
        <div className={stylesLR.refreshSeccion}>
          <div className={stylesLR.containerData}>
            <div className={stylesLR.containerLastR}>
              <h4 className={stylesLR.lastRefreshLabel}>Last refresh</h4>
            </div>
            <p className={stylesLR.dateStyle}>{lastRefreshTime()}</p>
          </div>
          <div className={stylesLR.reload}>
            <FaArrowsRotate color="white" size={16} onClick={handleReload} />
          </div>
        </div>
      </div>
    </div>
  );
};
