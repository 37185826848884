import React, { useMemo, useReducer } from 'react';
import { MainState, MainContextProviderProps } from '@interfaces/interfaces';
import { MainContext } from './mainContext';
import { mainReducer } from './mainReducer';

const initialState: MainState = {
  atoken: '',
  forgotPasswordFlow: false,
  step: 1,
  passwordChanged: false,
  successPasswordChanged: false,
  successCode: false,
  errorStep1: false,
  errorStep2: false,
  errorMessage: '',
  loading: false,
  username: '',
};

export const MainContextProvider: React.FC<MainContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <MainContext.Provider value={providerValue}>
      {children}
    </MainContext.Provider>
  );
};
