import React, { createContext, useState, useContext, useMemo } from 'react';

export interface FormData {
  firstName: string;
  lastName: string;
  streetAddress: string;
  country: string;
  city: string;
  zipCode: number | null;
  cardType: string;
  cardHolderName: string;
  cardNumber: string;
  expDate: Date | Date[] | undefined;
  agreed: boolean;
}

const FormDataContext = createContext<FormData | any>({});

export const useFormData = () => useContext(FormDataContext);
interface FormDataProviderProps {
  children: React.ReactNode;
}

export const FormDataProvider: React.FC<FormDataProviderProps> = ({
  children,
}) => {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    streetAddress: '',
    country: '',
    city: '',
    zipCode: null,
    cardType: '',
    cardHolderName: '',
    cardNumber: '',
    expDate: undefined,
    agreed: false,
  });

  const contextValue = useMemo(() => [formData, setFormData], [formData]);

  return (
    <FormDataContext.Provider value={contextValue}>
      {children}
    </FormDataContext.Provider>
  );
};
