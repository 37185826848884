import { DataTable, DataTableSortOrderType } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnHeaders, ColumnHeadersRecipients, SortEnum } from '@enums/enums';
import { TagStatus } from '@components/TagStatus/TagStatus';
import { TagStateRecipients } from '@components/TagStateRecipients/TagStateRecipients';
import { ButtonReSendEmail } from '@components/ButoonReSendEmail/ButtonReSendEmail';
import styles from './Table.module.scss';
import { TransactionTypeLabel } from '../TransactionTypeLabel/TransactionTypeLabel';
import { TableFooter } from '../TableFooter/TableFooter';
import { FilterSection } from '../FilterSection/FilterSection';
import { EmailFilter } from '../EmailFilter/EmailFilter';
import {
  EmailData,
  StatusType,
  TagStateRecipientsButtonType,
  TagStateRecipientsType,
} from './types';

interface ColumnHeader {
  key: string;
  label: string;
}

export const Table = (props: any) => {
  const sortField = ColumnHeaders.transactionId;

  const {
    loading,
    tableData,
    columnHeaders,
    searchButtonHandler,
    orderByHandler,
    resetFiltersHandler,
    typeDropdownHandler,
    filterDropdownHandler,
    defaultCurrentPage,
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    resultAmount,
    pageSizes,
    changePageSizeHandler,
    isNextPage,
    isPrevPage,
    setPrevPage,
    setSortOrder,
    sortOrder,
    fetchDataAndSetCooldown,
    isRecipients,
    handleEmailFilter,
  } = props;

  const orderBy = (reset?: boolean) => {
    let sortValue: DataTableSortOrderType = 0;
    let sorting: string | undefined;
    if (!reset) {
      if (sortOrder === 0) {
        sortValue = -1;
        sorting = SortEnum.descending;
      } else if (sortOrder === -1) {
        sortValue = 1;
        sorting = SortEnum.ascending;
      }
      orderByHandler(
        true,
        true,
        currentPageSize,
        currentPage,
        sortField,
        sorting
      );
    }
    setSortOrder(sortValue);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const TableFooterWithPagination = () => {
    return (
      <TableFooter
        isNextPage={isNextPage}
        isPrevPage={isPrevPage}
        setPrevPage={setPrevPage}
        defaultCurrentPage={defaultCurrentPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setCurrentPageSize={setCurrentPageSize}
        currentPageSize={currentPageSize}
        resultAmount={resultAmount}
        pageSizes={pageSizes}
        searchPageHandler={searchButtonHandler}
        changePageSizeHandler={changePageSizeHandler}
        resetOrderHandler={orderBy}
      />
    );
  };

  const template = (transactionType: ColumnHeader) => {
    return <TransactionTypeLabel transaction={transactionType} />;
  };

  const templateStatus = (status: StatusType) => {
    return <TagStatus status={status} />;
  };

  const templateStatusRecipients = (state: TagStateRecipientsType) => {
    return <TagStateRecipients status={state} />;
  };
  const templateButtonRecipients = (state: any) => {
    return (
      <ButtonReSendEmail
        status={state}
        fetchDataAndSetCooldown={fetchDataAndSetCooldown}
      />
    );
  };
  const templateEmail = ({ email }: EmailData) => {
    return <b>{email}</b>;
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const TransactionFilterSection = () => {
    if (isRecipients) {
      return (
        <EmailFilter
          onFilter={handleEmailFilter}
          fetchDataAndSetCooldown={fetchDataAndSetCooldown}
        />
      );
    }
    return (
      <FilterSection
        isLoading={loading}
        resetFiltersHandler={resetFiltersHandler}
        searchButtonHandler={searchButtonHandler}
        typeDropdownHandler={typeDropdownHandler}
        filterDropdownHandler={filterDropdownHandler}
        fetchDataAndSetCooldown={fetchDataAndSetCooldown}
      />
    );
  };

  return (
    <DataTable
      className={styles.table}
      tableStyle={{
        backgroundColor: 'white !important',
      }}
      showGridlines
      loading={loading}
      value={tableData.data}
      scrollable
      header={TransactionFilterSection}
      footer={TableFooterWithPagination}
      columnResizeMode="expand"
      removableSort
      sortMode="single"
      sortOrder={sortOrder}
      sortField={sortOrder !== 0 ? sortField : undefined}
      defaultSortOrder={0}
      onSort={() => orderBy()}
    >
      {columnHeaders.map((header: any) => {
        if (header[0] === ColumnHeaders.transactionType) {
          return (
            <Column
              key={header[0]}
              field={header[0]}
              header={header[1]}
              body={template}
              style={{ maxWidth: '150px' }}
            />
          );
        }

        if (header[0] === ColumnHeaders.operationStatus) {
          return (
            <Column
              key={header[0]}
              field={header[0]}
              header={header[1]}
              body={templateStatus}
            />
          );
        }

        if (header[0] === ColumnHeadersRecipients.state) {
          return (
            <Column
              key={header}
              field={header[0]}
              header={header[1]}
              body={templateStatusRecipients}
            />
          );
        }
        if (header[0] === ColumnHeadersRecipients.action) {
          return (
            <Column
              key={header}
              field={header[0]}
              header={header[1]}
              body={templateButtonRecipients}
              style={{ maxWidth: '180px' }}
            />
          );
        }
        if (header[0] === ColumnHeadersRecipients.email) {
          return (
            <Column
              key={header}
              field={header[0]}
              header={header[1]}
              body={templateEmail}
              style={{ minWidth: '300px' }}
            />
          );
        }
        if (header[0] === ColumnHeadersRecipients.creationDate) {
          return (
            <Column
              key={header}
              field={header[0]}
              header={header[1]}
              style={{ maxWidth: '130px' }}
            />
          );
        }
        if (header[0] === ColumnHeadersRecipients.lastEmailDate) {
          return (
            <Column
              key={header}
              field={header[0]}
              header={header[1]}
              style={{ maxWidth: '150px' }}
            />
          );
        }
        if (header[0] === ColumnHeaders.amount) {
          return (
            <Column
              key={header}
              field={header[0]}
              header={header[1]}
              className={styles.amountField}
              bodyStyle={{ textAlign: 'left' }}
              style={{ maxWidth: '110px' }}
            />
          );
        }
        if (header[0] === ColumnHeaders.transactionId) {
          return (
            <Column
              key={header}
              field={header[0]}
              header={header[1]}
              sortable
            />
          );
        }

        return <Column key={header} field={header[0]} header={header[1]} />;
      })}
    </DataTable>
  );
};
