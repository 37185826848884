import React, { useState, useCallback, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputMaskChangeParams, InputMask } from 'primereact/inputmask';
import { useTranslation } from 'react-i18next';
import { FaArrowRight, FaEnvelope, FaDollarSign } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import Header from '@components/Header/Header';
import { ListPages } from '@enums/enums';
import SideBar from '@components/SideBar/SideBar';
import { useDisbursement } from '../../contexts/DisbursementContext';
import data from './currencies.json';

import styles from '../DashboardReports/DashboardReports.module.scss';
import style from './Disbursement.module.scss';
import '@styles/main.scss';

type InputMaskChangeEvent = React.ChangeEvent<HTMLInputElement> & {
  target: HTMLInputElement & { value: string };
};

const Disbursement = (props: any) => {
  const { t } = useTranslation();
  const {
    currency,
    amount,
    concept,
    email,
    setCurrency,
    setAmount,
    setConcept,
    setEmail,
  } = useDisbursement();

  const [activeTab, setActiveTab] = useState('Single');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [amountError, setAmountError] = useState<string | null>(null);
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [amountTouched, setAmountTouched] = useState(false);
  const [conceptError, setConceptError] = useState<string | null>(null);
  const [isConceptValid, setIsConceptValid] = useState(false);
  const emailRequiredError = t('disbursement.emailIsRequired');
  const validEmailError = t('disbursement.emailValid');
  const amountRequired = t('disbursement.amountRequired');
  const conceptRequiredError = t('disbursement.conceptIsRequired');
  const conceptMaxLength = 150;

  const { currencies } = data;
  const navigate = useNavigate();

  const isValidEmail = (input: string): boolean => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(input.toLowerCase());
  };

  const validateEmail = () => {
    if (!email) {
      setEmailError(emailRequiredError);
      setIsEmailValid(false);
    } else if (!isValidEmail(email)) {
      setEmailError(validEmailError);
      setIsEmailValid(false);
    } else {
      setEmailError(null);
      setIsEmailValid(true);
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.currentTarget.value);
  };

  const validateAmount = useCallback(() => {
    const amountValue = Number(amount);
    if (!amountValue) {
      setAmountError(amountRequired);
      setIsAmountValid(false);
    } else {
      setAmountError(null);
      setIsAmountValid(true);
    }
  }, [amount, amountRequired]);

  useEffect(() => {
    if (amountTouched) {
      validateAmount();
    }
  }, [amount, validateAmount, amountTouched]);

  const validateConcept = () => {
    const trimmedConcept = concept.trim();
    if (!trimmedConcept) {
      setConceptError(conceptRequiredError);
      setIsConceptValid(false);
    } else {
      setConceptError(null);
      setIsConceptValid(true);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^\d*\.?\d*$/.test(newValue)) {
      setAmount(newValue);
      setAmountTouched(true);
    }
  };

  const handleAmountBlur = () => {
    setAmountTouched(true);
    const newAmountValue = Number(amount).toFixed(2);
    setAmount(newAmountValue);
    validateAmount();
  };

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newEmailValue = e.target.value;
    if (!newEmailValue) {
      setEmailError(emailRequiredError);
      setIsEmailValid(false);
    } else if (!isValidEmail(newEmailValue)) {
      setEmailError(validEmailError);
      setIsEmailValid(false);
    } else {
      setEmailError(null);
      setIsEmailValid(true);
    }
  };

  const handleConceptBlur = () => {
    validateConcept();
  };

  const handleGoBack = () => {
    navigate(ListPages.DashboardReports, { replace: true });
  };

  const navigateToNewDisbursement = () => {
    validateEmail();
    validateAmount();
    validateConcept();

    if (isEmailValid && isAmountValid && isConceptValid) {
      navigate(ListPages.NewDisbursement);
    }
  };

  const renderContent = () => {
    if (activeTab === 'Single') {
      return (
        <div className={style.containerSingleTab}>
          <div className={style.containerSingleTab}>
            <div className={style.inputGroup}>
              <div className={style.currencyContainer}>
                <h4 className={style.descriptions}>
                  {t('disbursement.currency')}
                </h4>
                <Dropdown
                  className={style.dropdownStyle}
                  value={currency}
                  disabled
                  options={currencies}
                  placeholder="Select a Currency"
                  onChange={(e) => setCurrency(e.value)}
                />
              </div>
              <div className={style.amountContainer}>
                <h4 className={style.descriptions}>
                  {t('disbursement.amount')}
                </h4>
                <div
                  className={`p-inputgroup ${
                    amountError ? style.inputError : ''
                  }`}
                >
                  <span className={style.emailContainer}>
                    <FaDollarSign size={18} color="black" />
                  </span>
                  <InputText
                    className={style.inputTextStyle}
                    value={amount}
                    onChange={handleAmountChange}
                    onBlur={handleAmountBlur}
                    placeholder="0.00"
                  />
                </div>
                {amountError && (
                  <small className="p-error">{amountError}</small>
                )}
              </div>
            </div>
            <div className={style.conceptContainer}>
              <h4 className={style.descriptions}>{t('disbursement.email')}</h4>
              <div
                className={`p-inputgroup ${emailError ? style.inputError : ''}`}
              >
                <span className={style.emailContainer}>
                  <FaEnvelope size={18} color="black" />
                </span>
                <InputText
                  placeholder="example@example.com"
                  className={style.inputEmail}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={handleEmailBlur}
                />
              </div>
              {emailError && <small className="p-error">{emailError}</small>}
            </div>

            <div className={style.conceptContainer}>
              <h4 className={style.descriptions}>
                {t('disbursement.concept')}
              </h4>
              <InputText
                className={`${style.inputConcept} ${
                  conceptError ? style.inputError : ''
                }`}
                value={concept}
                onChange={(e) => setConcept(e.currentTarget.value)}
                maxLength={conceptMaxLength}
                onBlur={handleConceptBlur}
              />
              <small id="username-help">
                {concept?.length || 0}/{conceptMaxLength}{' '}
                {t('disbursement.conceptMaxLenght')}
              </small>
            </div>
            {!!conceptError && (
              <small className="p-error">{conceptError}</small>
            )}
          </div>
        </div>
      );
    }
    if (activeTab === 'Multiple') {
      return <div>Next</div>;
    }
    return null;
  };

  return (
    <div className={styles.pageContainer}>
      <Header />

      <div className={styles.mainSection}>
        <SideBar />
        <div className={styles.mainSectionWorkArea}>
          <div>
            <div className="disbursement-layout">
              <div className={styles.tableHeader}>
                <h1 className={styles.headerText}>{t('disbursement.title')}</h1>
              </div>
              <div className={style.disbursementWorkingArea}>
                <div className={style.tabButtonContainer}>
                  <Button
                    label="Single"
                    onClick={() => setActiveTab('Single')}
                    className={`customButton ${
                      activeTab === 'Single'
                        ? style.activeButton
                        : style.customButton
                    }`}
                  />
                  <Button
                    label="Multiple"
                    disabled
                    onClick={() => setActiveTab('Multiple')}
                    className={`customButton ${
                      activeTab === 'Multiple'
                        ? style.activeButton
                        : style.customButton
                    }`}
                  />
                </div>

                {renderContent()}

                <div className={style.containerButtonsDis}>
                  <Button
                    className={style.buttonContinue}
                    onClick={navigateToNewDisbursement}
                  >
                    {t('disbursement.buttonContinue')}
                    <FaArrowRight size={20} className={style.rightIcon} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disbursement;
