/* eslint-disable prefer-destructuring */
import React, { useEffect, useMemo } from 'react';
import { ListPages, ProcessEnvEnum } from '@enums/enums';
import { B2cAuthErrorTypes } from '@enums/B2cAuthErrorTypesEnum';
import { useMsal } from '@azure/msal-react';

const getUrlParams = (currentURL: string) => {
  const params: { [key: string]: string } = {};
  const hashIndex = currentURL.indexOf('#');

  if (hashIndex !== -1) {
    const urlAfterHash = currentURL.substring(hashIndex + 1);

    const paramPairs = urlAfterHash.split('&');
    paramPairs.forEach((paramPair) => {
      const [key, value] = paramPair.split('=');
      params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
    });
  }
  return params;
};

const B2cRedirect = () => {
  const forgotRedirect: string =
    process.env['REACT_APP_AUTHORITY_FORGOT']?.toString() ?? '';
  const { instance } = useMsal();
  const urlParams: any = useMemo(() => {
    const currentUrl = window.location.href;
    const params = getUrlParams(currentUrl);
    return params;
  }, []);

  useEffect(() => {
    const b2cForgotPasswURL: string =
      process.env[ProcessEnvEnum.ForgotPasswordUrl]?.toString() ?? '';
    if (!urlParams?.error) return;

    if (urlParams?.error === B2cAuthErrorTypes.MissingToken) {
      window.location.href = `${ListPages.Login}?error=${B2cAuthErrorTypes.MissingToken}`;
    }

    if (
      urlParams?.error === B2cAuthErrorTypes.AccesDenied &&
      urlParams?.error_description.includes(
        'The user has forgotten their password'
      )
    ) {
      instance.loginRedirect({
        scopes: ['openid'],
        nonce: 'defaultNonce',
        authority: forgotRedirect,
      });
    }

    window.location.href = `${ListPages.Login}?error=${B2cAuthErrorTypes.UnknownError}`;
  }, [urlParams, instance, forgotRedirect]);

  return null;
};

export default B2cRedirect;
