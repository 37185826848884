import { axiosFuex } from '@httpClients';
import { ListPages } from '@enums/enums';
import { B2cAuthErrorTypes } from '@enums/B2cAuthErrorTypesEnum';

import { InvoiceInfoType } from 'types/InvoiceInfoType';

export const saveInvoiceInfo = async (
  body: InvoiceInfoType,
  userToken: string
): Promise<{ status: number; data?: any; errorMessage?: string }> => {
  try {
    const path = `/recipients/single`;
    const response = await axiosFuex.patch(path, { ...body, userToken });

    return { status: response.status, data: response.data };
  } catch (error: any) {
    let errorMessage = 'An unexpected error occurred';

    if (error.response) {
      errorMessage = error.response.data?.message || errorMessage;
      if (error.response.status === 401 || error.response.status === 403) {
        window.location.href = `${ListPages.Login}?error=${B2cAuthErrorTypes.MissingToken}`;
      }
      return { status: error.response.status, errorMessage };
    }

    return { status: 500, errorMessage };
  }
};
