import styles from './TransactionTypeLabel.module.scss';
import { scenarioLabels } from '../../enums/ScenariosEnum';

export const TransactionTypeLabel = (props: { transaction: any }) => {
  const { transaction } = props;

  const scenarioLabel = scenarioLabels.find(
    (e) => transaction.transactionType === e.code
  );
  return (
    <div
      className={`${styles.transactionTypeLabel} 
      ${styles[`${scenarioLabel?.color}Border` || 'black']}`}
    >
      <i
        className={`${scenarioLabel?.icon} 
        ${styles.icon}
         ${styles[scenarioLabel?.color || 'black']}`}
      />
      <span className={styles[scenarioLabel?.color || 'black']}>
        {[scenarioLabel?.name || 'Transaction']}
      </span>
    </div>
  );
};
