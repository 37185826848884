import React from 'react';
import Header from '@components/Header/Header';
import NavBar from '@components/NavBar/NavBar';
import styles from './MyProfile.module.scss';

import useNavigationGuards from '../../hooks/useNavigationGuards';
import ChangePassword from '../ChangePassword/ChangePassword';

const MyProfile = (props: any) => {
  return (
    useNavigationGuards() || (
      <div>
        <Header />
        <h1 className={styles.sectionTitle}>My Profile</h1>
        <div className="divider" />
        <div className={styles.mainSection}>
          <NavBar />
          <ChangePassword />
        </div>
      </div>
    )
  );
};

export default MyProfile;
