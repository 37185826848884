import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { fetchRecipientsdReportData } from '@services/recipients-info';
import Header from '@components/Header/Header';
import SideBar from '@components/SideBar/SideBar';
import { ColumnHeadersRecipients, ListPages } from '@enums/enums';
import { Table } from '@components/Table/Table';
import { RecipientsReportTypeType } from 'types/responses/recipients-reports';
import { useNavigate } from 'react-router-dom';
import { DataTableSortOrderType } from 'primereact/datatable';
import styles from '../DashboardReports/DashboardReports.module.scss';
import style from './Recipients.module.scss';
import './Recipients.scss';

const Recipients = (props: any) => {
  const { t } = useTranslation();

  const columnHeaders = [
    [ColumnHeadersRecipients.email, t('recipients.columnHeaders.email')],
    [ColumnHeadersRecipients.fullName, t('recipients.columnHeaders.fullName')],
    [
      ColumnHeadersRecipients.creationDate,
      t('recipients.columnHeaders.creationDate'),
    ],
    [
      ColumnHeadersRecipients.lastEmailDate,
      t('recipients.columnHeaders.lastEmailDate'),
    ],
    [ColumnHeadersRecipients.state, t('recipients.columnHeaders.state')],
    [ColumnHeadersRecipients.action, t('recipients.columnHeaders.action')],
  ];
  const navigate = useNavigate();
  const defaultPageSize = 15;
  const defaultCurrentPage = 1;

  const rowsPerPageOptions = [15, 20, 30, 50];
  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);
  const [transactionData, setTransactionData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [isPrevPage, setIsPrevPage] = useState(false);
  const [isNextPage, setIsNextPage] = useState(false);
  const [emailFilter, setEmailFilter] = useState('');
  const [cursorKeyCollection, setCursorKeyCollection] = useState<any[]>([{}]);
  const [resultAmount, setResultAmount] = useState<number>(0);
  const [pagesAmount, setPagesAmount] = useState();
  const [sortOrder, setSortOrder] = useState<DataTableSortOrderType>(0);

  const formatDate = (dateString: string): string => {
    return dateString?.split('T')[0];
  };

  const updateTransactionDates = (body: any) => {
    if (body && body.data) {
      const updatedData = body.data.map((item: any) => {
        if (item.creationDate) {
          return {
            ...item,
            creationDate: formatDate(item.creationDate),
            lastEmailDate: formatDate(item.lastEmailDate),
          };
        }
        return item;
      });

      setTransactionData({ ...body, data: updatedData });
    }
  };

  const fetchData = async (
    cursor: PaginationRecipientsCursorType,
    pageSize: number,
    isInitialLoad: boolean
  ): Promise<void> => {
    const finalCursor: PaginationRecipientsCursorType = {
      ...cursor,
    };

    if (!isInitialLoad) {
      const lastEvaluatedKey = cursorKeyCollection[currentPage - 1];
      finalCursor.lastEvaluatedKey = lastEvaluatedKey;
    }

    try {
      const response = await fetchRecipientsdReportData(finalCursor, pageSize);

      if (response && response.data) {
        const res = response as RecipientsReportTypeType;
        updateTransactionDates(res);

        const nextPageExistence = !!res.lastEvaluatedKey;
        setIsNextPage(nextPageExistence);

        if (nextPageExistence) {
          const updatedCursorKeyCollection = [...cursorKeyCollection];
          updatedCursorKeyCollection[currentPage] = res.lastEvaluatedKey;
          setCursorKeyCollection(updatedCursorKeyCollection);
        }

        setResultAmount(res.totalResults || 0);
      } else {
        setTransactionData([]);
        setIsNextPage(false);
        setResultAmount(0);
      }
    } catch (err) {
      console.error('Error al obtener los datos:', err);
      setTransactionData([]);
      setIsNextPage(false);
      setResultAmount(0);
    }
  };

  useEffect(() => {
    const body: PaginationRecipientsCursorType = {
      filters: {},
    };

    if (emailFilter) {
      body.filters.email = emailFilter;
    }

    fetchData(body, currentPageSize, !!emailFilter || currentPage === 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentPageSize, emailFilter]);

  const handleEmailFilter = (email: string): void => {
    setEmailFilter(email);
  };

  const changePageSizeHandler = (
    isFilterChange: number,
    isPageSizeChange: boolean,
    newPageSize: number
  ) => {
    setCurrentPageSize(newPageSize);
    setCurrentPage(1);
  };

  return (
    <div>
      <div className={styles.pageContainer}>
        <Header />
        <div>
          <div className={styles.mainSection}>
            <SideBar />
            <div className={styles.mainSectionWorkArea}>
              <div>
                <div className="table-layout">
                  <div className={styles.tableHeader}>
                    <h1 className={styles.headerText}>
                      {t('recipients.title')}
                    </h1>
                    <Button
                      className={style.exportButton}
                      icon="pi pi-plus"
                      label={t('recipients.upload') as string}
                      onClick={() => {
                        navigate(ListPages.UploadRecipients, { replace: true });
                      }}
                    />
                  </div>
                  <div className={styles.tableWorkingAreaRecipients}>
                    <Table
                      fetchDataAndSetCooldown={fetchData}
                      columnHeaders={columnHeaders}
                      tableData={transactionData}
                      pageSizes={rowsPerPageOptions}
                      changePageSizeHandler={changePageSizeHandler}
                      defaultCurrentPage={defaultCurrentPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      currentPageSize={currentPageSize}
                      setCurrentPageSize={setCurrentPageSize}
                      isPrevPage={isPrevPage}
                      isNextPage={isNextPage}
                      setPrevPage={setIsPrevPage}
                      pagesAmount={pagesAmount}
                      setSortOrder={setSortOrder}
                      orderBy={() => {}}
                      handleEmailFilter={handleEmailFilter}
                      isRecipients
                      resultAmount={resultAmount}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recipients;
